//
// Images
// -------------------------

@import "lib/app.scss";
/*

$img-bg-loading:  '/assets/img/bg-body.svg';
$logo-img:        '/assets/img/icons.svg';
$bg-body-dummy:   '/assets/img/bg-body.svg';
$start-logo:      '/assets/img/login_logo.png';
*/


// filter:invert(0.88) hue-rotate(180deg);


.logo-container {
    background-image: url($img-bg-loading);
}


.icon-top {
    background-image: url($logo-img);
}

logo-block .main_logo {
    background-image: url($icon-logo);
}

.left-layout-container__content-dummy::after {
    background-image: url($bg-body-legacy);
}

#login_brand_logo {
    background-image: url($start-logo);
}

