/*layout*/
html, body{
	width: 100%;
	height: 100%;
}
body{
	overflow-x: hidden;
}

// menu

.app-content-body {
//	padding-bottom: $app-header-height;
	position: relative;
	flex: 1;
	flex-shrink: 0;
	flex-basis: auto;

	display: flex;
	flex-direction: column;
}


.hbox{
	display: table;
	table-layout: fixed;
	border-spacing: 0;
	width: 100%;
	height: 100%;
	.col{
		display: table-cell;
		vertical-align: top;
		height: 100%;
		float: none;
	}
}

.v-middle{vertical-align: middle !important,}
.v-top{vertical-align: top !important,}
.v-bottom{vertical-align: bottom !important,}

.vbox{
	display: table;
	border-spacing:0;
	position: relative;
	width: 100%;
	height: 100%;
	min-height: 240px;
	.row-row {
		display: table-row;
		height: 100%;
		> .cell {
			position: relative;
			height: 100%;
			width: 100%;
			.ie & {
				display: table-cell;
				overflow: auto;
			    .cell-inner{
			      overflow: visible !important;
			    }
			}
			.cell-inner {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				-webkit-overflow-scrolling:touch;
				overflow: auto;
			}
		}
	}
}

// navbar

.navbar{
	.navbar-form-sm{
		margin-top: 10px;
		margin-bottom: 10px;
	}
	border-width: 0;
	border-radius: 0;
	margin: 0;
}

.navbar-md{
	min-height: $app-header-md-height;
	.navbar-btn{
		margin-top: 13px;
	}
	.navbar-form{
		margin-top: 15px;
	}
	.navbar-nav > li > a{
		padding-top: 20px;
		padding-bottom: 20px;
	}
	.navbar-brand{
		line-height: 60px;
	}
}

.navbar-brand{
	float: none !important;
	text-align: center;
	font-size: 20px;
	font-weight: 700;
	height: auto;
	line-height: $app-header-height;
	display: inline-block;
	padding: 0 20px;
	&:hover{
		text-decoration: none;
	}
	img{
		max-height: 20px;
		margin-top: -4px;
		vertical-align: middle;
		display: inline;
	}
}

.navbar-buttons {

  .nav {
    display: inline-block;
  }

  a.dropdown-toggle {
    height: 50px;
    line-height: 50px;
    display: inline-block;
    padding: 0px 10px;
  }
}

@media (min-width: 768px) {


	.fixed-tool-panel {
		padding-left: $app-aside-width;
	}

	.col.show{
		display: table-cell !important;
	}
}

// md
@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
  .hbox-auto-md{
    display: block;
    > .col{
      width: auto;
      height: auto;
      display: block;
      &.show{
        display: block !important;
      }
    }
    .vbox{
      height: auto;
    }
    .cell-inner{
      position: static !important;
    }
  }
}


// sm
@media (min-width: 768px) and (max-width: 991px) {
	.hbox-auto-sm{
		display: block;
		> .col{
			width: auto;
			height: auto;
			display: block;
			&.show{
				display: block !important;
			}
		}
		.vbox{
			height: auto;
		}
		.cell-inner{
			position: static !important;
		}
	}
}
// xs
@media (max-width: 767px) {
	body{
//		height:auto;
		min-height: 100%;
	}
	.navbar-fixed-bottom{
		position: fixed;
	}

	/*
	.app-content-full{
		position: relative;
		top: 0;
		width: 100% !important;
	}
	*/
	.hbox-auto-xs{
		display: block;
		> .col{
			width: auto;
			height: auto;
			display: block;
		}
		.vbox{
			height: auto;
		}
		.cell-inner{
			position: static !important;
		}
	}
	.navbar-nav{
		margin-top: 0;
		margin-bottom: 0;
		> li > a{
			box-shadow:0 -1px 0 rgba(0,0,0,0.1);
			.up{
				top: 0;
			}
			.avatar{
				width: 30px;
				margin-top: -5px;
			}
		}
		.open .dropdown-menu{
			background-color: $brand-white;
		}
	}
	.navbar-form{
		box-shadow:0 -1px 0 rgba(0,0,0,0.1);
		margin-top: 0 !important;
		margin-bottom: 0 !important;
		.form-group{
			margin-bottom: 0;
		}
	}
}


/** Scrollbars **/

