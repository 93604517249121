.btn {
  font-weight: 500;
  border-radius: $btn-border-radius;
  outline: 0 !important;
  white-space: nowrap;
}

.btn-link {
  color: $text-color;
  &.active {
    webkit-box-shadow: none;
    box-shadow: none;
  }
}

.btn-default {
  @include button-variant($text-color, $btn-default-bg, $btn-default-border);
  background-color: $brand-white;
  border-bottom-color: darken($btn-default-border, 2%);
  @include box-shadow(0 1px 1px rgba(90, 90, 90, 0.1));
  &.btn-bg {
    border-color: rgba(0, 0, 0, 0.1);
    background-clip: padding-box;
  }
}

.btn-primary {
  @include button-variant($brand-white, $brand-primary, $brand-primary);
}

.btn-success {
  @include button-variant($brand-white, $brand-success, $brand-success);
}

.btn-info {
  @include button-variant($brand-white, $brand-info, $brand-info);
}

.btn-warning {
  @include button-variant($brand-black, $brand-warning, $brand-warning);
}

.btn-danger {
  @include button-variant($brand-white, $brand-danger, $brand-danger);
}

.btn-dark {
  @include button-variant($brand-white, $brand-dark, $brand-dark);
}

.btn-black {
  @include button-variant($brand-white, $brand-black, $brand-black);
}

.btn-icon {
  padding: 0 !important;
  text-align: center;
  width: 34px;
  height: 34px;
  i {
    top: -1px;
    position: relative;
    line-height: 34px;
  }
  &.btn-sm {
    width: 30px;
    height: 30px;
    i {
      line-height: 30px;
    }
  }
  &.btn-lg {
    width: 45px;
    height: 45px;
    i {
      line-height: 45px;
    }
  }
}

.btn-rounded {
  border-radius: 50px;
  padding-left: 15px;
  padding-right: 15px;
  &.btn-lg {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.btn {
  > i {
    &.pull-left,
    &.pull-right {
      line-height: $line-height-base;
    }
  }
}

.btn-block {
  padding-left: 12px;
  padding-right: 12px;
}

.btn-group-vertical > .btn:first-child:not(:last-child) {
  border-top-right-radius: $border-radius-base;
}

.btn-group-vertical > .btn:last-child:not(:first-child) {
  border-bottom-left-radius: $border-radius-base;
}

.btn-addon {
  i {
    margin: -7px -12px;
    margin-right: 12px;
    background-color: rgba(0, 0, 0, 0.1);
    width: 34px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    float: left;
    position: relative;
    border-radius: $btn-border-radius 0 0 $btn-border-radius;
    &.pull-right {
      margin-right: -12px;
      margin-left: 12px;
      border-radius: 0 $btn-border-radius $btn-border-radius 0;
    }
  }
  &.btn-sm {
    i {
      margin: -6px -10px;
      margin-right: 10px;
      width: 30px;
      height: 30px;
      line-height: 30px;
      &.pull-right {
        margin-right: -10px;
        margin-left: 10px;
      }
    }
  }
  &.btn-lg {
    i {
      margin: -11px -16px;
      margin-right: 16px;
      width: 45px;
      height: 45px;
      line-height: 45px;
      &.pull-right {
        margin-right: -16px;
        margin-left: 16px;
      }
    }
  }
  &.btn-default {
    i {
      background-color: transparent;
      border-right: 1px solid $border-color;
    }
  }
}

@media screen and (max-width: 992px) {
  .btn-hidden-xs-animate {

    padding-right: 0;
    @include transition(padding 0.5s ease-in-out 0s);

    &.btn-addon i {
      margin-right: 0;
      @include transition(margin 0.5s ease-in-out 0s);
    }

    .btn-name {
        margin-right: -300px;
        @include transition(margin 0.5s ease-in-out 0s);
    }

  }

  .btn-hidden-xs-animate:hover {
    padding-right: 12px;
    .btn-name {
      margin-right: 0;
    }

    &.btn-addon i {
      margin-right: 12px;
    }

  }
}



@mixin btn-addon_btn-collapse {


  overflow: hidden;
  padding-right: 0;
  @include transition(padding 0.5s ease-in-out 0s);

  &.btn-addon i {
    border-right: none;
    margin-right: 0;
    @include transition(margin 0.5s ease-in-out 0s);
  }

  .btn-name {
    margin-right: -300px;
    @include transition(margin 0.5s ease-in-out 0s);
  }


  &:hover {

    padding-right: 12px;
    .btn-name {
      margin-right: 0;
    }

    &.btn-addon i {
      border-right: inherit;
      margin-right: 12px;
    }

  }

}

.btn-addon.btn-collapse {
    @include btn-addon_btn-collapse();
}

@media (max-width: $screen-xs-max) {
.btn-addon.collapse-xs {
    @include btn-addon_btn-collapse();
  }
}

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
  .btn-addon.collapse-sm {
    @include btn-addon_btn-collapse();
  }
}

@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
  .btn-addon.collapse-md {
    @include btn-addon_btn-collapse();
  }
}






