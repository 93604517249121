@mixin color-variant($bg-color: #555, $lt-percent: 10%, $lter-percent: 15%, $dk-percent: 10%, $dker-percent: 15%) {
  background-color: $bg-color;
  &.lt,
  .lt {
    @include color-schema($bg-color, -$lt-percent, -2.5%, !important);
  }
  &.lter,
  .lter {
    @include color-schema($bg-color, -$lter-percent, -5%, !important);
  }
  &.dk,
  .dk {
    @include color-schema($bg-color, $dk-percent, 2.5%, !important);
  }
  &.dker,
  .dker {
    @include color-schema($bg-color, $dker-percent, 5%, !important);
  }
  &.bg,
  .bg{
    background-color: $bg-color;
  }
}

@mixin color-schema($bg-color: #555, $percent: 15%, $sat-percent: null,  $content:null) {
  @if $percent >=0 {

    @if $sat-percent >=0 {
      background-color: saturate(darken($bg-color, $percent), $sat-percent)  $content;
    } @else {
      background-color: desaturate(darken($bg-color, $percent), -$sat-percent) $content;
    }

  } @else {
    @if $sat-percent >=0 {
      background-color: saturate(lighten($bg-color, -$percent), $sat-percent) $content;
    } @else {
      background-color: desaturate(lighten($bg-color, -$percent), -$sat-percent) $content;
    }
  }
}

@mixin font-variant($bg-color, $font-color, $link-color: #fff, $hover-color: #fff) {
  color: $font-color;
  a {
    color: $link-color;
    &:hover{
      color: $hover-color;
    }
    &.list-group-item{
      &:hover,
      &:focus{
        background-color: inherit;
      }
    }
  }
  .nav {
    > li {
      &:hover,
      &:focus,
      &.active{
        > a{
          color: $hover-color;
          @include color-schema($bg-color, 5%, 2.5%);
        }
      }
      > a{
        color: darken($link-color, 5%);
        &:hover,
        &:focus{
          @include color-schema($bg-color, 3%, 2.5%);
        }
      }
    }
    .open > a{
      @include color-schema($bg-color, 5%, 2.5%);
    }
  }
  .caret{
    border-top-color: $font-color;
    border-bottom-color: $font-color;
  }
  &.navbar .nav{
    > li.active > a{
      color: $hover-color;
      @include color-schema($bg-color, 5%, 2.5%);
    }
  }
  .open > a {
    &,
    &:hover,
    &:focus{
      color: $hover-color;
    }
  }
  .text-muted {
    color: darken($font-color, 10%) !important;
  }
  .text-lt {
    color: lighten($font-color, 25%) !important;
  }
  &.auto,
  .auto{
    .list-group-item{
      border-color: darken($bg-color, 5%) !important;
      background-color: transparent;
      &:hover,
      &:focus,
      &:active,
      &.active{
        @include color-schema($bg-color, 5%, 2.5%,  !important) ;
      }
    }
  }
}

@mixin text-wariant($bg-color, $name) {
  a.bg-#{"" +$name}:hover{
    background-color: darken($bg-color, 5%);
  }
  a.text-#{"" +$name}:hover{
    color: darken($bg-color, 5%);
  }
  .text-#{"" +$name}{
    color: $bg-color;
  }
  .text-#{"" +$name}-lt{
    color: lighten($bg-color, 5%);
  }
  .text-#{"" +$name}-lter{
    color: lighten($bg-color, 10%);
  }
  .text-#{"" +$name}-dk{
    color: darken($bg-color, 5%);
  }
  .text-#{"" + $name}-dker{
    color: darken($bg-color, 10%);
  }
}


@mixin clearfix() {
  &:before,
  &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

// Button variants
// -------------------------
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons
@mixin button-variant($color, $background, $border) {
  color: $color !important;
  background-color: $background;
  border-color: $border;

  &:hover,
  &:focus,
  &:active,
  &.active,
  .open .dropdown-toggle#{&} {
    color: $color !important;
    background-color: darken($background, 5%);
    border-color: darken($border, 8%);
  }
  &:active,
  &.active,
  .open .dropdown-toggle#{&} {
    background-image: none;
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &:active,
    &.active {
      background-color: $background;
      border-color: $border
    }
  }
}

@mixin translateZ($z) {
  -webkit-transform: translateZ($z);
  -ms-transform: translateZ($z);
  -o-transform: translateZ($z);
  transform: translateZ($z);
}

// CSS3 PROPERTIES
// --------------------------------------------------

// Single side border-radius
@mixin border-top-radius($radius) {
  border-top-right-radius: $radius;
  border-top-left-radius: $radius;
}
@mixin border-right-radius($radius) {
  border-bottom-right-radius: $radius;
  border-top-right-radius: $radius;
}
@mixin border-bottom-radius($radius) {
  border-bottom-right-radius: $radius;
  border-bottom-left-radius: $radius;
}
@mixin border-left-radius($radius) {
  border-bottom-left-radius: $radius;
  border-top-left-radius: $radius;
}

// Drop shadows
@mixin box-shadow($shadow) {
  -webkit-box-shadow: $shadow; // iOS <4.3 & Android <4.1
  box-shadow: $shadow;
}

// Transitions
@mixin transition($transition) {
  -webkit-transition: $transition;
  transition: $transition;
}
@mixin transition-delay($transition-delay) {
  -webkit-transition-delay: $transition-delay;
  transition-delay: $transition-delay;
}
@mixin transition-duration($transition-duration) {
  -webkit-transition-duration: $transition-duration;
  transition-duration: $transition-duration;
}
@mixin transition-transform($transition) {
  -webkit-transition: -webkit-transform $transition;
  -moz-transition: -moz-transform $transition;
  -o-transition: -o-transform $transition;
  transition: transform $transition;
}

// Transformations
@mixin rotate($degrees) {
  -webkit-transform: rotate($degrees);
  -ms-transform: rotate($degrees); // IE9+
  transform: rotate($degrees);
}
@mixin scale($ratio) {
  -webkit-transform: scale($ratio);
  -ms-transform: scale($ratio); // IE9+
  transform: scale($ratio);
}
@mixin translate($x, $y) {
  -webkit-transform: translate($x, $y);
  -ms-transform: translate($x, $y); // IE9+
  transform: translate($x, $y);
}
@mixin skew($x, $y) {
  -webkit-transform: skew($x, $y);
  -ms-transform: skewX($x) skewY($y); // See https://github.com/twbs/bootstrap/issues/4885; IE9+
  transform: skew($x, $y);
}
@mixin translate3d($x, $y, $z) {
  -webkit-transform: translate3d($x, $y, $z);
  transform: translate3d($x, $y, $z);
}

// Backface visibility
// Prevent browsers from flickering when using CSS 3D transforms.
// Default value is `visible`, but can be changed to `hidden`
// See git pull https://github.com/dannykeane/bootstrap.git backface-visibility for examples
@mixin backface-visibility($visibility) {
  -webkit-backface-visibility: $visibility;
  -moz-backface-visibility: $visibility;
  backface-visibility: $visibility;
}

// Box sizing
@mixin box-sizing($boxmodel) {
  -webkit-box-sizing: $boxmodel;
  -moz-box-sizing: $boxmodel;
  box-sizing: $boxmodel;
}

// User select
// For selecting text on the page
@mixin user-select($select) {
  -webkit-user-select: $select;
  -moz-user-select: $select;
  -ms-user-select: $select; // IE10+
  -o-user-select: $select;
  user-select: $select;
}

// Resize anything
@mixin resizable($direction) {
  resize: $direction; // Options: horizontal, vertical, both
  overflow: auto; // Safari fix
}

// CSS3 Content Columns
@mixin content-columns($column-count, $column-gap: $grid-gutter-width) {
  -webkit-column-count: $column-count;
  -moz-column-count: $column-count;
  column-count: $column-count;
  -webkit-column-gap: $column-gap;
  -moz-column-gap: $column-gap;
  column-gap: $column-gap;
}

// Optional hyphenation
@mixin hyphens($mode: auto) {
  word-wrap: break-word;
  -webkit-hyphens: $mode;
  -moz-hyphens: $mode;
  -ms-hyphens: $mode; // IE10+
  -o-hyphens: $mode;
  hyphens: $mode;
}

// Opacity
@mixin opacity($opacity) {
  opacity: $opacity;
  // IE8 filter
  $opacity-ie: ($opacity * 100);
  filter: "alpha(opacity=#{$opacity-ie})";
}



// GRADIENTS
// --------------------------------------------------


  // Horizontal gradient, from left to right
  //
  // Creates two color stops, start and end, by specifying a color and position for each color stop.
  // Color stops are not available in IE9 and below.
  @mixin gradient-horizontal($start-color: #555, $end-color: #333, $start-percent: 0%, $end-percent: 100%) {
    background: argb($start-color);
    background-image: -webkit-gradient(linear, $start-percent top, $end-percent top, from($start-color), to($end-color)); // Safari 4+, Chrome 2+
    background-image: -webkit-linear-gradient(left, color-stop($start-color $start-percent), color-stop($end-color $end-percent)); // Safari 5.1+, Chrome 10+
    background-image: -moz-linear-gradient(left, $start-color $start-percent, $end-color $end-percent); // FF 3.6+
    background-image:  linear-gradient(to right, $start-color $start-percent, $end-color $end-percent); // Standard, IE10
    background-repeat: repeat-x;
//    filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=1)",argb($start-color),argb($end-color))); // IE9 and down
}

  // Vertical gradient, from top to bottom
  //
  // Creates two color stops, start and end, by specifying a color and position for each color stop.
  // Color stops are not available in IE9 and below.
  @mixin gradient-vertical($start-color: #555, $end-color: #333, $start-percent: 0%, $end-percent: 100%) {
    background: argb($start-color);
    background-image: -webkit-gradient(linear, left $start-percent, left $end-percent, from($start-color), to($end-color)); // Safari 4+, Chrome 2+
    background-image: -webkit-linear-gradient(top, $start-color, $start-percent, $end-color, $end-percent); // Safari 5.1+, Chrome 10+
    background-image:  -moz-linear-gradient(top, $start-color $start-percent, $end-color $end-percent); // FF 3.6+
    background-image: linear-gradient(to bottom, $start-color $start-percent, $end-color $end-percent); // Standard, IE10
    background-repeat: repeat-x;
//    filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=0)",argb($start-color),argb($end-color))); // IE9 and down
}

  @mixin gradient-directional($start-color: #555, $end-color: #333, $deg: 45deg) {
    background-repeat: repeat-x;
    background-image: -webkit-linear-gradient($deg, $start-color, $end-color); // Safari 5.1+, Chrome 10+
    background-image: -moz-linear-gradient($deg, $start-color, $end-color); // FF 3.6+
    background-image: linear-gradient($deg, $start-color, $end-color); // Standard, IE10
  }
  @mixin gradient-horizontal-three-colors($start-color: #00b3ee, $mid-color: #7a43b6, $color-stop: 50%, $end-color: #c3325f) {
    background: argb($start-color);
    background-image: -webkit-gradient(left, linear, 0 0, 0 100%, from($start-color), color-stop($color-stop, $mid-color), to($end-color));
    background-image: -webkit-linear-gradient(left, $start-color, $mid-color $color-stop, $end-color);
    background-image: -moz-linear-gradient(left, $start-color, $mid-color $color-stop, $end-color);
    background-image: linear-gradient(to right, $start-color, $mid-color $color-stop, $end-color);
    background-repeat: no-repeat;
//    filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=1)",argb($start-color),argb($end-color))); // IE9 and down, gets no color-stop at all for proper fallback
  }
  @mixin gradient-vertical-three-colors($start-color: #00b3ee, $mid-color: #7a43b6, $color-stop: 50%, $end-color: #c3325f) {
    background: argb($start-color);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from($start-color), color-stop($color-stop, $mid-color), to($end-color));
  background-image: -webkit-linear-gradient($start-color, $mid-color $color-stop, $end-color);
  background-image: -moz-linear-gradient(top, $start-color, $mid-color $color-stop, $end-color);
  background-image: linear-gradient($start-color, $mid-color $color-stop, $end-color);
  background-repeat: no-repeat;
  //    filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=0)",argb($start-color),argb($end-color))); // IE9 and down, gets no color-stop at all for proper fallback
}
@mixin gradient-radial($inner-color: #555, $outer-color: #333) {
  background-image: -webkit-gradient(radial, center center, 0, center center, 460, from($inner-color), to($outer-color));
  background-image: -webkit-radial-gradient(circle, $inner-color, $outer-color);
  background-image: -moz-radial-gradient(circle, $inner-color, $outer-color);
  background-image: radial-gradient(circle, $inner-color, $outer-color);
  background-repeat: no-repeat;
}
@mixin gradient-striped($color: #555, $angle: 45deg) {
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(.25, rgba(255,255,255,.15)), color-stop(.25, transparent), color-stop(.5, transparent), color-stop(.5, rgba(255,255,255,.15)), color-stop(.75, rgba(255,255,255,.15)), color-stop(.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient($angle, rgba(255,255,255,.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,.15) 50%, rgba(255,255,255,.15) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient($angle, rgba(255,255,255,.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,.15) 50%, rgba(255,255,255,.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient($angle, rgba(255,255,255,.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,.15) 50%, rgba(255,255,255,.15) 75%, transparent 75%, transparent);
}

// Reset filters for IE
//
// When you need to remove a gradient background, don't forget to use this to reset
// the IE filter for IE9 and below.
@mixin reset-filter() {
  //  filter: e(%("progid:DXImageTransform.Microsoft.gradient(enabled = false)"));
}
