@import "app.utilities";
@import "app.variables";

.loya-range-box {

  .min-name,
  .max-name {
    display: block;
    @extend .text-muted;
    @extend .f-s-8;
    @extend .m-b-xs
  }

  .min-name {
    @extend .pull-left;
  }
  .max-name {
    @extend .pull-right;
  }

  .min-name:empty,
  .max-name:empty {
    display: none;
  }

  @mixin track {
    width: 100%;
    height: 0.4em;
    cursor: pointer;
    background: $input-bg;
    border-radius: $input-border-radius;
    border: 1px solid $input-border;
    @include box-shadow(inset 0 1px 1px rgba(0,0,0,0.1));
    @content;
  }

  @mixin thumb {
    border: 1px solid darken($brand-success, 3%);
    height: 1.2em;
    width: 1.2em;
    border-radius: 300px;
    @include color-variant($brand-success, 5%, 10%, 5%, 10%);
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -0.5em;
    box-shadow: none;
  }

  @mixin fillLower {
    background: $brand-success;
    height: 0.4em;
    border-radius: $input-border-radius;
  }

  input[type=range] {
    -webkit-appearance: none;
    margin: 10px 0;
    width: 100%;
    border: none;
    box-shadow: none;
  }
  input[type=range]:focus {
    outline: none;
  }

  input[type=range]::-webkit-slider-runnable-track {
    @include track;
  }

  input[type=range]::-webkit-slider-thumb {
    @include thumb;
  }

  input[type=range]::-webkit-slider-thumb:after{
    content: "asdasd";
  }

  input[type=range]:focus::-webkit-slider-runnable-track {
    box-shadow: none;
  }

  input[type=range]::-moz-range-track {
    @include track;
  }

  input[type=range]::-moz-range-thumb {
    @include thumb;
  }
  input[type=range]::-moz-range-progress {
    @include fillLower;
  }
  input[type=range]::-moz-focus-outer {
    border: 0;
  }

  input[type=range]::-ms-track {
    @include track;
  }

  input[type=range]::-ms-fill-lower {
    @include fillLower;
  }

  input[type=range]::-ms-thumb {
    @include thumb;
  }
  input[type=range]:focus::-ms-fill-lower {
    background: $brand-success;
  }

}