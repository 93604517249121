.bg-gd{
  @include gradient-vertical(rgba(40,50,60,0), rgba(40,50,60,0.075), 0, 100%);
  filter:none;
}

.bg-gd-dk {
  @include gradient-vertical(rgba(40,50,60,0), rgba(40,50,60,0.5), 10%, 100%);
  filter:none;
}

.bg-white-opacity{
	background-color: rgba(255, 255, 255, 0.5);
}
.bg-black-opacity{
	background-color: rgba(32, 43, 54, 0.5);
}

.bg-light {
	@include color-variant($brand-light, 2%, 3%, 3%, 5%);
	color: $text-color;
}

.bg-dark {
	@include color-variant($brand-dark, 5%, 10%, 5%, 10%);
	@include font-variant($brand-dark, lighten($brand-dark,40%), lighten($brand-dark,50%), $brand-white);
}

.bg-black {
	@include color-variant($brand-black, 5%, 10%, 5%, 10%);
	@include font-variant($brand-black, lighten($brand-black,40%), lighten($brand-black,50%), $brand-white);
}

.bg-primary {
	@include color-variant($brand-primary, 5%, 10%, 5%, 10%);
	@include font-variant($brand-primary, lighten($brand-primary,50.13%), lighten($brand-primary,50%), $brand-white);
}

.bg-success {
	@include color-variant($brand-success, 5%, 10%, 5%, 10%);
	@include font-variant($brand-success, lighten($brand-success,40%), lighten($brand-success,50%), $brand-white);
}

.bg-info {
	@include color-variant($brand-info, 5%, 10%, 5%, 10%);
	@include font-variant($brand-info, lighten($brand-info,40%), lighten($brand-info,50%), $brand-white);
}

.bg-warning {
	@include color-variant($brand-warning, 5%, 10%, 5%, 10%);
	@include font-variant($brand-warning, lighten($brand-warning,40%), lighten($brand-warning,50%), $brand-white);
}

.bg-danger {
	@include color-variant($brand-danger, 5%, 10%, 5%, 10%);
	@include font-variant($brand-danger, lighten($brand-danger,40%), lighten($brand-danger,50%), $brand-white);
}

.bg-white {
	background-color: $brand-white;
	color: $text-color;
	a {
		color: $link-color;
		&:hover{
			color: darken($link-color, 10%);
		}
	}
	.text-muted{color: $text-muted !important;}
	.lt,
	.lter,
	.dk,
	.dker{
		background-color: $brand-white;
	}
}
.bg-white-only{background-color:$brand-white;}
.bg-white-opacity{
	background-color: rgba(255, 255, 255, 0.5);
}
.bg-black-opacity{
	background-color: rgba(32, 43, 54, 0.5);
}

a.bg-light{
	&:hover{
		color: $link-color;
	}
}

@include text-wariant($brand-primary, primary);
@include text-wariant($brand-info, info);
@include text-wariant($brand-success, success);
@include text-wariant($brand-warning, warning);
@include text-wariant($brand-danger, danger);
@include text-wariant($brand-dark, dark);
@include text-wariant($brand-black, black);

.text-white {
  color: $brand-white;
}
.text-black {
	color: #000;
}

.text-muted {
  color: $text-muted;
}

.bg{
	background-color: $body-bg;
}
