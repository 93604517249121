
$font-size-base: 1rem;
$modal-inner-padding: 1rem;
$enable-caret: false;
$card-spacer-x: 15px;
$input-padding-y: 0.5rem;
$input-placeholder-color: #999;
$btn-padding-y-sm: 0.35rem;
$btn-padding-x-sm: 0.65rem;
$btn-padding-y: 0.5rem;
$popover-arrow-width: 5px;
@import "~bootstrap/scss/bootstrap";

.col {
  padding: 0px;
  width: auto;
}
