// @import "npm/font-awesome$4.6.3/less/font-awesome.scss";



@font-face {
  font-family: Museo;
  src: url("./../../fonts/Museo-.eot") format('eot'), url("./../../fonts/Museo.woff") format('woff'), url("./../../fonts/Museo.ttf") format('truetype'), url("./../../fonts/Museo.svg#Museo") format('svg')
}

@font-face {
  font-family: Museo;
  src: url("./../../fonts/MuseoBold-.eot") format('eot'), url("./../../fonts/MuseoBold.woff") format('woff'), url("./../../fonts/MuseoBold.ttf") format('truetype'), url("./../../fonts/MuseoBold.svg#MuseoBold") format('svg');
  font-weight: 700
}

@font-face {
  font-family: Museo;
  src: url("./../../fonts/MuseoLight-.eot") format('eot'), url("./../../fonts/MuseoLight.woff") format('woff'), url("./../../fonts/MuseoLight.ttf") format('truetype'), url("./../../fonts/MuseoLight.svg#MuseoLight") format('svg');
  font-weight: 300
}


