input, optgroup, select, textarea {
  font-weight: 300;
  border: 1px solid #ddd;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
  background: $brand-white;
  -webkit-transition: all ease-in-out .3s;
  -o-transition: all ease-in-out .3s;
  transition: all ease-in-out .3s;

}

html { font-size: $font-size-base-px; }

.pager li>a:hover, .pager li>a:focus {
  background-color: transparent;
}

.bg-white { color:#333333; background-color:$brand-white; }
.bg-white a { color:#5fa4e1/*#363f44*/; }
.bg-white a:hover { color:#1f2427; }
.bg-white .text-muted { color:#c7c7c7!important; color:rgba(0,0,0,.5)!important; }
.bg-white .lt,
.bg-white .lter,
.bg-white .dk,
.bg-white .dker { background-color:$brand-white; }

.bg-white a.list-group-item:hover,
.bg-white a.list-group-item:focus { background-color:inherit; }
.bg-white .caret { border-top-color:#a7a7a7; border-bottom-color:#a7a7a7; }
.bg-white.navbar .nav>li.active>a { color:#333; background-color:#d5ddf2; }
.bg-white .open>a { color:#888; }
.bg-white .open>a:hover,
.bg-white .open>a:focus { color:#333; }
.bg-white .text-lt { color:#d9d9d9!important; }
.bg-white.auto .list-group-item,
.bg-white .auto .list-group-item { background-color:transparent; border-color:#eaeaea!important; }
.bg-white.auto .list-group-item:hover,
.bg-white .auto .list-group-item:hover,
.bg-white.auto .list-group-item:focus,
.bg-white .auto .list-group-item:focus,
.bg-white.auto .list-group-item:active,
.bg-white .auto .list-group-item:active,
.bg-white.auto .list-group-item.active,
.bg-white .auto .list-group-item.active { background-color:#cacaca!important; }

.bg-white .navbar-brand:hover,
.bg-white .nav>li:hover>a,
.bg-white .nav>li:focus>a,
.bg-white .nav>li.active>a { color:#333; /*background-color:#393939;*/ }
.bg-white .nav>li>a { color:#222; }
.bg-white .nav>li>a:hover,
.bg-white .nav>li>a:focus { background-color:#d5ddf2; }
.bg-white .nav .open>a { background-color:#d5ddf2; }
.bg-white .navbar-brand,
.bg-white .nav>li>a,
.bg-white .nav>li>a { color:#333333; background-color:$brand-white; }
.bg-white .navbar-brand:hover,
.bg-white .nav>li:hover>a,
.bg-white .nav>li:focus>a,
.bg-white .nav>li.active>a { color:#333; background-color:#d5ddf2; }
.bg-white .nav>li li:hover>a,
.bg-white .nav>li li:focus>a,
.bg-white .nav>li li.active>a { color:#333; background-color:#dfe6f5;}
.bg-white .nav-sub>li:hover>a,
.bg-white .nav-sub>li:focus>a,
.bg-white .nav-sub>li.active>a { color:#333; background-color:#dfe6f5;}


.bg-white-only { background-color: $brand-white; }
.bg-white-opacity { background-color:rgba(255,255,255,0.5); }
.bg-black-opacity { background-color:rgba(32,43,54,0.5); }



/****----- my fixes -------*/


 .app-content {
  margin-left: 200px;
}


.navbar-brand {
  display: block;
  height: 50px;
  padding: 0px;
  font-size: 1.5em;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 50px;
  text-align: left;
  position: relative;
  -webkit-transition: background-color .2s ease-in-out 0s;
  transition: background-color .2s ease-in-out 0s;
  width: 200px;
  color: #5b5b5b;
}

.navbar-brand > span {
  margin-left: 5px;
  display: inline-block;
  vertical-align: middle;
}


.navbar-brand i {
  vertical-align: middle;
  width: 50px !important;
}



html { background: #f4f4f4; }

.table > tbody > tr:first-child td.middle {
  padding: 0;
  text-align: right;
  vertical-align: middle;
}

.card-body:after {
  clear: both;
}

.card-body:after, .card-body:before {
  display: table;
  content: " ";
}

.btn-group-xs > .btn, .btn-xs {
  padding: .35rem .4rem;
  font-size: .875rem;
  line-height: .5;
  border-radius: .2rem;
}


.modal-dialog {
  transform: translate(0) !important;

  .modal-content {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  }
}


.modal-auto {
  max-width: 98%;
}
