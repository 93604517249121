@font-face {
  font-family: 'fa-viber';
  src:  url('./../../fonts/fa-viber.eot');
  src:  url('./../../fonts/fa-viber.eot#iefix') format('embedded-opentype'),
        url('./../../fonts/fa-viber.ttf') format('truetype'),
        url('./../../fonts/fa-viber.woff') format('woff'),
        url('./../../fonts/fa-viber.svg#fa-viber') format('svg');
  font-weight: normal;
  font-style: normal;
}

.fa-cur-kzt,
.fa-cur-grn,
.fa-cur-br,
.fa-viber {
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;


  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fa-viber:before {
  font-family: 'fa-viber';
  content: "\e900";
}

.fa-cur-grn:before {
  font-family: Museo;
  content: "\20B4";
}

.fa-cur-br:before {
  font-family: Museo;
  content: "Br";
}

.fa-cur-kzt:before {
  font-family: Museo;
  content: "\20B8";
}
