.frootable {

  &.ui-grid {
    width: 100%;
    border: none;
    border-bottom: 1px solid $border-color;

    .ui-grid-header {
      border-top: 1px solid #e7e7e7;
      border-bottom: none;
      overflow:inherit;

      .ui-grid-top-panel {
        overflow:inherit;

        .ui-grid-header-viewport {
          overflow:inherit;

          .ui-grid-cell-contents {
            overflow:inherit;
          }
        }
      }
    }

    .ui-grid-header .ui-grid-header-cell {
      vertical-align: top;
      font-weight: 500;
      color: #888 !important;

      white-space: nowrap;

    //  border-bottom: 1px solid #e7e7e7;

      //line-height: 1.42857143;
      border-top: none;

      background: #fafafa;

    }
    .ui-grid-header .ui-grid-header-cell .ui-grid-cell-contents {
      padding-bottom: 10px;
      padding: 8px 15px;
    }

    .ui-grid-top-panel {
      background: none;
      border: none;
      font-weight: inherit;

    }

    .ui-grid-vertical-bar {
      display: none;
    }

    .ui-grid-cell {
      border-left: none;
      border-right: none;
      padding: 0;
      margin: 0;
      border-top: 1px solid #e7e7e7;

      .ui-grid-cell-contents {
        padding: 8px 15px;

      }
    }

    .ui-grid-viewport .ui-grid-row:nth-child(odd) .ui-grid-cell {
      background: none;
    }

    .ui-grid-viewport .ui-grid-row:nth-child(even) .ui-grid-cell {
      background: #fafafa;
    }


  }
}