::-webkit-scrollbar {
	display: inherit;
	-webkit-appearance: none;
	width  : $scroll-bar-width;
	height : $scroll-bar-width;
	background: rgba(0, 0, 0, .0);
}

body {
	scrollbar-width: thin;
	scrollbar-color: rgba(0, 0, 0, .3);
}

::-webkit-scrollbar * {
	background: rgba(0, 0, 0, .0);
}

::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background-color: rgba(0, 0, 0, .3);
}

::-webkit-scrollbar-track {
	background-color: rgba(0, 0, 0, .0);
}


.noscroll::-webkit-scrollbar {
	display: none;
}

.noscroll {
	-ms-overflow-style: none ;
	overflow-y: hidden ;
}

.noscroll:hover {
	-ms-overflow-style: auto;
	overflow-y: auto;
}

/*@-moz-document url-prefix() {
  .noscroll {
    overflow-y: auto;
  }
}*/


.noscroll::-webkit-scrollbar {
	display: none;
}

.noscroll::-webkit-scrollbar-track-piece {
	display: none;
}

.noscroll:hover::-webkit-scrollbar {
	display: inherit;
}
/*
.noscroll:hover::-webkit-scrollbar * {
	background: rgba(0, 0, 0, .0);
}
*/

/*.noscroll:hover::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background-color: rgba(0, 0, 0, .3);
}*/


.mini-scroll::-webkit-scrollbar {
	display: inherit;
/*	-webkit-appearance: none;
	width: 7px;
	height: 7px;
	background: transparent;*/
}

/*.mini-scroll::-webkit-scrollbar * {
	background: transparent;
}*/
/*

.mini-scroll::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background-color: rgba(0, 0, 0, .3);
}
*/

.details-screen.menu-show .details-nav::-webkit-scrollbar {
	display: none;
}
