@import "app.variables.scss";

/* -- fixes -- */

.p-t-none {
  padding-top: 0 !important;
}

.p-l-none {
  padding-left: 0 !important;
}

.p-r-none {
  padding-right: 0 !important;
}

.p-b-none {
  padding-bottom: 0 !important;
}

.nav-tabs li a {
  padding-left: 20px;
  padding-right: 20px;
}

.tab-container .tab-content {
  padding-left: 20px;
  padding-right: 20px;
}


/* -- fixes -- end */

.bg-white-only {
  background-color: $brand-white;
}

.bg-white-opacity {
  background-color: rgba($brand-white, 0.5);
}

.bg-black-opacity {
  background-color: rgba($brand-black, 0.5);
}


@media print {
  .wrapper-md {
    margin-top: 0;
    padding-top: 0
  }

  .display-as-report {
    padding: 0;
    border: 0;
    box-shadow: none
  }

  .report-header {
    display: block;
    padding: 0 0 30px 0;
    font-size: .857em;
  }

  .report-header h3 {
    font-size: 2em;
  }

  .report-header h4 {
    font-size: 1.286em;
  }

  .row, .card, .panel, table, table th, table td {
    font-size: .857em;
  }
}

.bootstrap-touchspin span, .bootstrap-touchspin input, .bootstrap-touchspin button {
  margin-bottom: 0;
}

.bootstrap-touchspin-up, .bootstrap-touchspin-down {
  height: 18px;
  text-align: center
}

.bootstrap-touchspin .input-group-btn-vertical i {
  left: 16px !important;
}

.bootstrap-touchspin-prefix {
  border-right: 0;
}

h1 {
  margin-bottom: 40px;
}

h3 {
  margin-bottom: 20px;
}

.pager li {
  margin-bottom: 20px;
}

.f-r .pager li {
}

.padding {
  padding: 15px;
}

.table .checkbox {
  margin-top: 4px;
  margin-bottom: 0;
  display: inline-block
}

.table tr td {
  padding-top: 14px;
}

.table tr {
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.indicator-hide .footable-sort-indicator {
  display: none !important
}

.table {
}

.table.middle, .table.middle tr, .table.middle td {
  vertical-align: middle
}

.table.p-t-15 tr td {
  padding-top: 15px;
}

.table.p-b-15 tr td {
  padding-bottom: 15px;
}

.footable-row-detail-name {
  font-weight: 500 !important;
}

.footable-row-detail-cell {
  font-size: .9em
}

.border-bottom {
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-radius: 0 !important;
}

.list-group-item {
  margin-bottom: 0 !important
}

.icon-circle {
  border: 2px solid $brand-success;
  border-radius: 50%;
  padding: 7px;
  color: $brand-success;
  font-size: 1.4em;
  min-height: 30px;
  min-width: 30px;
  display: inline-block
}

.cursorhover tbody tr:hover {
  background: rgba(0, 0, 0, 0.05) !important
}

.nav.nav-tabs > li > a {
  margin-right: 0
}

.border2-top {
  border-top: 2px solid rgba(0, 0, 0, .05)
}

.border2-bottom {
  border-bottom: 2px solid rgba(0, 0, 0, .05)
}

.border1-top {
  border-top: 1px solid rgba(0, 0, 0, .05)
}

.border1-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, .05)
}

.border1-top-dashed {
  border-top: 1px dashed rgba(0, 0, 0, .05)
}

.border1-bottom-dashed {
  border-bottom: 1px dashed rgba(0, 0, 0, .05)
}

.border1-top-dotted {
  border-top: 1px dotted rgba(0, 0, 0, .05)
}

.border1-bottom-dotted {
  border-bottom: 1px dotted rgba(0, 0, 0, .05)
}

.dashed {
  border-style: dashed
}

.dotted {
  border-style: dotted
}

.bigger {
  font-size: 1.3em;
}

.f-s-8 {
  font-size: .8em !important
}

.f-s-7 {
  font-size: .7em !important
}

.f-s-6 {
  font-size: .6em !important
}

.f-s-5 {
  font-size: .5em !important
}

.f-s-10 {
  font-size: 1em !important
}

.f-s-12 {
  font-size: 1.2em !important
}

.f-s-14 {
  font-size: 1.4em !important
}

.f-s-15 {
  font-size: 1.5em !important
}

.f-s-16 {
  font-size: 1.6em !important
}

.f-s-17 {
  font-size: 1.7em !important
}

.f-s-18 {
  font-size: 1.8em !important
}

.f-s-19 {
  font-size: 1.9em !important
}

.f-s-20 {
  font-size: 2.0em !important
}

.db-icon {
  padding: 10px 10px !important;
}

.db-icon .caret {
  margin: 0 0 10px 4px
}

.description-col {
  float: left;
  width: 50%
}

.description-col .padding {
  padding: 0 15px 0 95px;
  position: relative;
}

.parametrs-col {
  width: 50%;
  float: right;
  min-width: 260px;
}

.parametrs-subcol {
  float: right;
  min-width: 220px;
}

.parametrs-subcol .padding {
  padding: 0 15px
}

.parametrs-subcol .col {
  float: left;
  width: 33%
}

.actions-subcol {
  width: 40%;
  display: inline-block;
  float: right;
  text-align: right;
  margin-bottom: 20px;
}

@media screen and (max-width: $screen-md-min) {
  .description-col {
    float: none;
    width: 100%
  }

  .description-col:before {
  }

  .description-col:after {
    display: table;
    content: " ";
    clear: both
  }

  .parametrs-col {
    width: 100%;
    float: none;
    min-width: 260px;
  }

  .parametrs-col .padding {
    padding-left: 100px;
  }

  .parametrs-subcol {
    float: left;
    width: 60%;
  }

  .actions-subcol {
    width: 40%;
    display: inline-block;
    float: right;
    text-align: right;
    margin-bottom: 20px;
  }

}

.btn-addon i.text-primary,
.btn-addon i.text-success,
.btn-addon i.text-info,
.btn-addon i.text-warning,
.btn-addon i.text-danger {
  background-color: rgba(255, 255, 255, 0.25);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0 !important;
}

.db-header .db-pad {
  padding: 0 0 0 5px;
  margin: 0;
}

@media (min-width: $screen-sm-min) {
  .db-outter-cont {
    width: 200px;
    height: 100%;
    position: fixed;
    overflow: hidden;
    top: 0px;
  }

  .db-inner-cont {
    position: absolute;
    left: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    width: 100%;
    height: 100%;
    -ms-overflow-style: none;
  }

  .db-inner-pad {
    padding: 70px 5px 30px 5px;
  }

  .db-shadow {
    position: absolute;
    top: 70px;
    width: 100%;
    height: 1px;
    background: rgba(0, 0, 0, .35);
    z-index: 1000024;
    opacity: 0;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
    opacity: 0; /*-webkit-transition:opacity .25s ease-in-out; -moz-transition:opacity .25s ease-in-out; -ms-transition:opacity .25s ease-in-out; -o-transition:opacity .25s ease-in-out; transition:opacity .25s ease-in-out; */
  }

  .db-right-cont {
    right: 20px;
  }

  .db-left-cont {
    left: 90px;
  }

  .db-pad {
    margin: 70px 240px 30px 310px;
  }

  .db-center-cont-shadow {
    position: fixed;
    width: 100%;
    height: 10px;
  }

  .db-center-cont-shadow .db-shadow {
    top: 0px;
  }

  .db-center-cont-shadow .db-pad {
    margin-top: 0;
    margin-bottom: 0;
    position: relative;
  }

  .db-center-cont .db-pad {
    padding: 0px 5px 0 5px
  }

  .db-center-cont-shadow .db-pad {
    padding: 0
  }

  .no-db-left-cont .db-pad {
    margin-left: 90px
  }

  .no-db-right-cont .db-pad {
    margin-right: 20px
  }

  .no-db-left-cont .db-left-cont {
    display: none
  }

  .no-db-right-cont .db-right-cont {
    display: none
  }

  .db-menu-lg .db-menu {
    width: 200px;
  }

  .db-menu-lg .db-pad {
    margin-left: 440px;
  }

  .db-menu-lg .db-left-cont {
    left: 220px;
  }

  .db-menu-lg.no-db-left-cont .db-pad {
    margin-left: 220px;
  }

  .db-header .db-pad {
    margin-left: 90px
  }

  .db-menu-lg .db-header .db-pad {
    margin-left: 220px
  }

  .db-menu {
    z-index: 1002
  }

  .db-menu .db-pad {
    padding: 20px 0px 20px;
    margin: 0;
  }

}

.header-action {
  margin: 20px 20px 0 0
}


@media (max-width: $screen-xs-max) {
  .db-left-cont,
  .db-right-cont,
  .db-center-cont {
    margin: 20px;
  }

  .no-db-left-cont .db-pad {
    margin-left: 20px
  }

  .db-menu {
    z-index: 1002
  }

  .db-menu .db-pad {
    padding: 20px 0px 20px;
    margin: 0;
  }

  .header-text {
    position: absolute;
    top: 0;
    left: 0
  }

  .db-header h2 {
    margin: 10px 0 0 20px;
    font-size: 1.8em;
  }

  .right-side-search-but {
    position: absolute;
    right: 20px;
    z-index: 1024;
    opacity: .6;
    padding: 8px 10px 0 0;
  }

}

.icon-top {
  width: 40px;
  height: 40px;
  display: inline-block;
  background-repeat: no-repeat;
}

.icon-top.i1 {
  background-position: 6px 0;
}

a:hover .icon-top.i1 {
  background-position: -44px 0px;
}



.icon-pok {
  width: 45px;
  height: 40px;
  display: inline-block;
  background-image: url(/assets/img/icons-pok.svg);
  background-repeat: no-repeat;
  position: relative;
  margin: -12px 5px -12px -19px
}

.icon-pok.i1 {
  background-position: 6px 0;
}

.icon-pok.i2 {
  background-position: 6px -40px;
}

.icon-pok.i3 {
  background-position: 6px -80px;
}

.icon-pok.i4 {
  background-position: 6px -120px;
}

.icon-pok.i5 {
  background-position: 6px -160px;
}

.icon-pok.i6 {
  background-position: 6px -200px;
}

.icon-pok.i7 {
  background-position: 6px -240px;
}

.icon-pok.i8 {
  background-position: 6px -280px;
}

.icon-pok.i9 {
  background-position: 6px -320px;
}

.icon-pok.i0 {
  background-position: 6px -360px;
}


.border-bottom {
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-radius: 0 !important;
}

.filter-item {
  border: 1px dotted rgba(0, 0, 0, .15);
  border-width: 1px 0 1px 0;
  padding: 0 30px
}

.border2-top {
  border-top: 2px solid rgba(0, 0, 0, .05)
}

.border2-bottom {
  border-bottom: 2px solid rgba(0, 0, 0, .05)
}

.border1-top {
  border-top: 1px solid rgba(0, 0, 0, .05)
}

.border1-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, .05)
}

.border1-top-dashed {
  border-top: 1px dashed rgba(0, 0, 0, .05)
}

.border1-bottom-dashed {
  border-bottom: 1px dashed rgba(0, 0, 0, .05)
}

.border1-top-dotted {
  border-top: 1px dotted rgba(0, 0, 0, .05)
}

.border1-bottom-dotted {
  border-bottom: 1px dotted rgba(0, 0, 0, .05)
}

.dashed {
  border-style: dashed
}

.dotted {
  border-style: dotted
}

.info-view .row {
  margin-left: 0;
  margin-right: 0;
  padding: 15px 0;
}

.info-view .row h4 {
  margin-top: 0;
  margin-bottom: 5px;
  padding: 0
}


.bigger {
  font-size: 1.3em;
}

.f-s-9 {
  font-size: .8em
}

.f-s-8 {
  font-size: .8em
}

.f-s-7 {
  font-size: .7em
}

.info-view * {
  line-height: 140%
}

.description-col {
  float: left;
  width: 50%
}

.description-col .padding {
  padding-left: 100px;
  position: relative;
}

.parametrs-col {
  width: 50%;
  float: right;
  min-width: 260px;
}

.parametrs-subcol {
  float: right;
  min-width: 220px;
}

.parametrs-subcol .padding {
}

.parametrs-subcol .col {
  float: left;
  width: 33%
}

.actions-subcol {
  width: 40%;
  display: inline-block;
  float: right;
  text-align: right;
  margin-bottom: 20px;
}

@media screen and (max-width: $screen-md-min) {
  .description-col {
    float: none;
    width: 100%
  }

  .description-col:before {
  }

  .description-col:after {
    display: table;
    content: " ";
    clear: both
  }

  .parametrs-col {
    width: 100%;
    float: none;
    min-width: 260px;
  }

  .parametrs-col .padding {
    padding-left: 100px;
  }

  .parametrs-subcol {
    float: left;
    width: 60%;
  }

  .actions-subcol {
    width: 40%;
    display: inline-block;
    float: right;
    text-align: right;
    margin-bottom: 20px;
  }

}



@media screen and (min-width: $screen-sm-min) {

  .input-var .btn-sm {
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    background: none
  }

  .input-var .form-control-sm {
    width: 20px !important;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    background: none
  }

  .input-var input, .input-var button {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border: none !important
  }

  .top-search {
    padding-right: 10px
  }
}


#main-header {
  font-size: 1.3em;
  font-weight: 300;
  float: left;
  display: inline-block;
  padding: 12px 15px 0 7px;
}

@media screen and (max-width: $screen-xs-max) {
  .db-right {
    width: 100%;
    margin: 0 1px;
    float: none !important;
    display: inline-block
  }

  .input-var {
    margin: 0 15px;
  }

  .no-pad-xs {
    padding-left: 0 !important;
    padding-right: 0 !important
  }


  .header-line {
    font-size: 17px;
    float: none;
    font-weight: 300;
    display: block;
    position: absolute;
    top: 12px;
    left: 80px;
  }

  .navbar-brand {
    width: 160px;
  }
}

.text-muted {
  color: inherit !important;
  opacity: .5
}

.app-aside {
  box-shadow: none;
}

.app-aside:before { /*box-shadow:2px 0 2px rgba(0,0,0,0.05), 1px 0 0 rgba(0,0,0,0.05);*/
}

.app-aside-folded .app-aside {
  box-shadow: none
}

.app-aside-folded .app-aside:before {
  box-shadow: 2px 0 2px rgba(0, 0, 0, 0.05), 1px 0 0 rgba(0, 0, 0, 0.05);
}


.navbar-brand {
  color: lighten( $text-color, 15% ) !important;
}

.hover-enlarge {
  width: 100%;
  overflow: hidden;
}

.hover-enlarge input {
  width: 100%;
}

.hover-enlarge label {
  float: left
}

.hover-enlarge span {
  display: block;
  overflow: hidden;
}

.hover-enlarge .btn {
  float: right;
}

.hover-enlarge input {
  padding: 0 10px
}

.hover-enlarge input, .formLine button {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 30px;
  border-radius: 4px;
}

.hover-enlarge .btn-hide {
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  width: 31px;
  overflow: hidden
}

.hover-enlarge .btn-hide.btn-40 {
  width: 41px;
}

.hover-enlarge .btn-hide:hover,
.hover-enlarge .btn-hide:focus,
.hover-enlarge .btn-hide:active {
  width: 130px
}

.hover-enlarge .input-hide {
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  width: 100px !important;
  overflow: hidden;
  height: 30px;
  display: inline-block;
  border-radius: 4px;
}

/** firefox fixing **/
.hover-enlarge .btn-hide {
  padding-left: 0px;
}

.hover-enlarge .btn-hide > i {
  margin-left: 0px;
}

.hover-enlarge button > span {
  display: inline-block;
  margin-bottom: -5px;
}

.hover-enlarge .input-hide:hover,
.hover-enlarge .input-hide:focus,
.hover-enlarge .input-hide:active {
  width: 200px !important
}

.hover-enlarge .db-new:hover,
.hover-enlarge .db-new:focus,
.hover-enlarge .db-new:active {
  width: 130px;
}

@media screen and (max-width: $screen-xs-max) {
  .hover-enlarge .input-hide,
  .hover-enlarge .input-hide,
  .hover-enlarge .input-hide {
    width: 80px !important
  }

  .hover-enlarge .input-hide:hover,
  .hover-enlarge .input-hide:focus,
  .hover-enlarge .input-hide:active {
    width: 120px !important
  }

  .hover-enlarge .db-new:hover,
  .hover-enlarge .db-new:focus,
  .hover-enlarge .db-new:active {
    width: 120px;
  }
}

a.link-light,
.link-light a {
  font-weight: 300
}


a.link-grey {
  color: lighten($text-color, 43%) !important
}

a.link-grey:hover,
a.link-grey:focus {
  color: $text-color !important
}

.link-grey a {
  color: lighten($text-color, 43%) !important
}

.link-grey a:hover,
.link-grey a:focus {
  color: $text-color !important
}


.table tr td a {
  vertical-align: middle
}

.table tr td {
  vertical-align: middle
}

.table tr {
  vertical-align: middle
}

.table > thead > tr > th {
  vertical-align: top;
  font-weight: 500;
  color: lighten($text-color, 33%) !important;
  padding-bottom: 8px;
  white-space: nowrap;
  position: relative
}

.table > thead > tr {
  border-bottom: 1px solid darken($table-border-color, 15%);
  border-top: 1px solid $table-border-color;
}

.table > tbody > tr:first-child td {
  padding-top: 10px;
}

.footable-odd {
  background: $brand-white
}

.popover-correction {
  width: 100%;
  display: block;
  position: absolute;
  left: 0;
  height: 20px;
  overflow: hidden;
}

.sort-indicator-center {
  text-align: center
}

.sort-indicator-center .footable-sort-indicator:before,
.sort-indicator-center .footable-sort-indicator {
  text-align: center;
  display: inline-block;
  padding: 0
}

/* Status:Черновик */

.db-col-type {
  padding-left: 0 !important;
  padding-right: 10px !important;
  width: 2%;
  text-align: center
}

.db-col-status {
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: 2%;
  text-align: center
}

.nav-scroll a {
  color: lighten( $text-color, 33% )  !important
}

.nav-scroll li:hover a,
.nav-scroll a:hover {
  color: $text-color !important
}

.nav-scroll .nav-active {
  background: $hover-bg !important;
  color: $text-color !important
}

.nav-scroll .nav-active a {
  color: $text-color !important
}

.line.pull-in {
  margin-right: 0px;
  margin-left: 0px;
}

.bootstrap-touchspin .input-group-btn-vertical > .btn {
  min-width: 30px;
}

.line-dotted {
  border-bottom: 1px dotted $border-color !important;
}

.details-nav {
  background: $brand-light
}

.details-nav,
.details-nav-top,
.details-nav-left,
.details-nav-left ul,
.details-nav-left ul li,
.details-nav-left ul li a {
  z-index: 1001 !important;
}

.details-nav .details-nav-left {
  position: fixed;
  width: 200px;
  height: 100%;
  background: $brand-light
}
.details-nav .details-nav-left:after {
  content: "";
  height: 100px;
  display: block;
}

//.details-nav .details-nav-left.noscroll {-ms-overflow-style: none; overflow: -moz-scrollbars-none;}
//.details-nav .details-nav-left.noscroll::-webkit-scrollbar {display: none;}
.details-nav .nav {
  width: 200px;
  padding-bottom: 0px;
}

.details-nav .nav > li {
  position: relative
}

.details-nav .nav > li > a {
  padding-left: 20px;
  padding-right: 20px;
  -webkit-transition: background-color ease-in-out .3s;
  -o-transition: background-color ease-in-out .3s;
  transition: background-color ease-in-out .3s
}

.details-nav .nav > li > i {
  float: left;
  margin: 0 10px 10px 0
}

.details-nav .nav > li:hover > a,
.details-nav .nav > li:focus > a,
.details-nav .nav > li.active > a {
  color: $text-color !important;
  background-color: $hover-bg;
}

.details-nav-top {
  display: none;
}

.details-info {
  margin-left: 200px !important;
  -webkit-transition: all ease-in-out .3s;
  -o-transition: all ease-in-out .3s;
  transition: all ease-in-out .3s;
}



.details-table,
.details-table .table,
.details-table .fixed-top-search,
.details-table .details-nav-left {
  width: 400px;
}

@media (min-width: $screen-lg-min) {
  .details-table,
  .details-table .table,
  .details-table .fixed-top-search,
  .details-table .details-nav-left {
    width: 600px;
  }
}

@media (min-width: 1400px) {
  .details-table,
  .details-table .table,
  .details-table .fixed-top-search,
  .details-table .details-nav-left {
    width: 700px;
  }
}

@media (min-width: 1600px) {
  .details-table,
  .details-table .table,
  .details-table .fixed-top-search,
  .details-table .details-nav-left {
    width: 800px;
  }
}

@media (max-width: $screen-sm-max) {
  .details-nav {
    width: 100% !important;
    overflow: visible;
  }

  .details-nav-top {
    display: block;
    width: 100%;
  }

  .details-nav .nav {
    width: 100%;
    padding-bottom: 0px;
  }

  .details-nav .details-nav-left {
    overflow: visible !important;
    position: relative !important;
    width: 100% !important;
    height: auto !important;
    padding-bottom: 0 !important;
    display: none;
  }

  .details-info {
    margin-left: 0px !important
  }

  .details-nav .nav > li > a {
    padding-left: 15px;
  }

  .details-table .table {
    width: 100%;
  }
}

@media (max-width: $screen-sm-min) {
  #main-header {
    display: none
  }
}

.details-info .row {
  margin-bottom: 5px;
}

.pointer {
  cursor: pointer
}

.pointer-default {
  cursor: default;
}

.vert-corrected-anchor {
  display: block;
  position: relative;
  top: -111px;
  visibility: hidden;
}

.form-control-edittable {
  height: auto;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.chosen-container {
  width: 100% !important
}

.h4 .form-control-edittable {
  font-size: 17px;
}

.rtl {
  border-top-left-radius: 4px !important
}

.rbl {
  border-bottom-left-radius: 4px !important;
}

.panel-inner-table .table > thead > tr {
  border-bottom: 0;
}

.panel-inner-table .table > thead > tr > th {
  padding: 10px 5px 10px 15px;
  text-align: left;
  font-size: 13px !important;
  color: lighten($text-color, 33%) !important;
  font-weight: 300 !important;
  word-wrap: break-word;
  white-space: normal;
  -webkit-hyphens: auto !important;
  -moz-hyphens: auto !important;
  hyphens: auto !important;
}

.panel-inner-table .table > thead > th > span,
.panel-inner-table .table > thead > th {
}

.panel-inner-table .table > tbody > tr:last-child {
  border-bottom: 0;
}

.panel-inner-table .table > tbody {
  border-bottom: 0;
}

.panel-inner-table .table tbody tr td {
  padding: 5px 5px 5px 15px;
}

.panel-inner-table .table > tfoot {
  border-bottom: 0;
}

.panel-inner-table .table > tfoot > tr {
  border-bottom: 0;
}

.panel-inner-table .table {
  border-bottom: 0;
}

.bootstrap-touchspin-up {
  padding: 2px !important;
}

.input-group-btn-vertical {
  height: 10px;
}

.form-label:not(.grid-item__label) {
  font-weight: 300;
  color: lighten($text-color, 33%);
  font-size: .8em;
  text-align: right;
  display: flex;
  justify-content: flex-end;
}

.form-label:not(.grid-item__label) i {
  float: right;
  margin: 1px 0 6px 5px;
  width: 16px;
  text-align: center;
  font-size: 1.2em;
  color: lighten( $text-color, 55%);
  order: 20;
}

.form-label:not(.grid-item__label) i.fa-2x {
  font-size: 2em;
  margin-top: -0.2em;
}


.form-label:not(.grid-item__label), .form-value:not(.grid-item__value) {
  position: relative;
  min-height: 1px;
  margin-bottom: 7px;

  overflow-wrap: break-word;
  word-break: normal;
  word-wrap: break-word;
}

.form-label:not(.grid-item__label) {
  float: left;
  width: 40%;
  /*
  word-break: break-word;
  -ms-word-break: break-word;
  */
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
  padding: 3px 0 0 15px;
}

.form-value:not(.grid-item__value) {
  float: left;
  width: 60%;
  padding: 0 0 0 10px;
}

.long-descriptions .form-label:not(.grid-item__label) {
  width: 60%
}

.long-descriptions .form-value:not(.grid-item__value) {
  width: 40%
}

.full-row .form-label:not(.grid-item__label) {
  width: 60%
}

.full-row .form-value:not(.grid-item__value) {
  width: 40%
}

.form-item:not(.grid-item__form) {
  width: 100%;
  float: left;
  margin: 0;
  position: relative;
}

.full-row.form-item:not(.grid-item__form) {
  width: 100%;
}

.form-item:not(.grid-item__form):hover .form-label:not(.grid-item__label) {
  color: darken(  $text-color, 6% );
 // color: $text-color;
 // font-weight: 500
}

.form-item:not(.grid-item__form):hover .form-value:not(.grid-item__value) {
  //font-weight: 900;
  text-shadow: 0px 0px 0px;
}

.br-fix:before,
.br-fix:after {
  display: table;
  content: " ";
}

.m-r-m-15 {
  margin-left: -15px;
}

@media (max-width: $screen-sm-min) {
  .form-label:not(.grid-item__label) {
    text-align: left
  }

  .form-label:not(.grid-item__label) i {
    float: left;
    margin: 1px 5px 6px 0
  }

}

@media (min-width: 640px) and (max-width: $screen-md-max) {
  .form-item:not(.grid-item__form) {
    width: 50%;
  }

  .form-label:not(.grid-item__label) {
    text-align: left
  }

  .br-fix:nth-child(4n+4):after {
    clear: both !important
  }

  /*.form-item:not(.grid-item__form):nth-child(4n+3) { color:#060 !important; border:1px solid #00f; border-radius:5px; border-radius:5px;  }*/
  .long-descriptions .form-item:not(.grid-item__form) {
    width: 100%;
  }

  .long-descriptions .form-label:not(.grid-item__label) {
    width: 50%
  }

  .long-descriptions .form-value:not(.grid-item__value) {
    width: 50%
  }

  .full-row.form-item:not(.grid-item__form) {
    width: 100%;
  }

  .full-row .form-label:not(.grid-item__label) {
    width: 50%
  }

  .full-row .form-value:not(.grid-item__value) {
    width: 50%
  }

}

@media (min-width: $screen-lg-min) and (max-width: 1439px) {
  .form-item:not(.grid-item__form) {
    width: 33.333333%;
  }

  .br-fix:nth-child(3n+3):after {
    clear: both !important
  }

  .long-descriptions .form-item:not(.grid-item__form) {
    width: 50%;
  }

  .long-descriptions .br-fix:nth-child(3n+3):after {
    clear: none !important
  }

  .long-descriptions .br-fix:nth-child(4n+4):after {
    clear: both !important
  }

  .full-row.form-item:not(.grid-item__form) {
    width: 100%;
  }

  .full-row .form-label:not(.grid-item__label) {
    width: 30%
  }

  .full-row .form-value:not(.grid-item__value) {
    width: 70%
  }

}

@media (min-width: 1440px) and (max-width: 1629px) {
  .form-item:not(.grid-item__form) {
    width: 25%;
  }

  .br-fix:nth-child(8n+8):after {
    clear: both !important
  }

  .long-descriptions .form-item:not(.grid-item__form) {
    width: 33.333333%;
  }

  .long-descriptions .br-fix:nth-child(8n+8):after {
    clear: none !important
  }

  .long-descriptions .br-fix:nth-child(3n+3):after {
    clear: both !important
  }

  .full-row.form-item:not(.grid-item__form) {
    width: 100%;
  }

  .full-row .form-label:not(.grid-item__label) {
    width: 20%
  }

  .full-row .form-value:not(.grid-item__value) {
    width: 80%
  }

}

@media (min-width: 1630px) {
  .form-item:not(.grid-item__form) {
    width: 20%;
  }

  .br-fix:nth-child(10n+10):after {
    clear: both !important
  }

  .long-descriptions .form-item:not(.grid-item__form) {
    width: 33.333333%;
  }

  .long-descriptions .br-fix:nth-child(10n+10):after {
    clear: none !important
  }

  .long-descriptions .br-fix:nth-child(3n+3):after {
    clear: both !important
  }

  .full-row.form-item:not(.grid-item__form) {
    width: 100%;
  }

  .full-row .form-label:not(.grid-item__label) {
    width: 20%
  }

  .full-row .form-value:not(.grid-item__value) {
    width: 80%
  }

}

.m-r-m-20 {
  margin-right: -15px
}

.i-switch .sign {
  display: block;
  position: absolute;
  left: 47px;
  top: 2px;
  color: $text-color;
  font-size: 14px;
}

.i-switch .sign.text-danger {
  color: $brand-danger;
}

.table > tbody > tr:hover {
  background: $hover-bg
}

.table th {
  background:  lighten( $brand-light,2% );
}

.table1 > tbody > tr.selected {
  background: $hover-bg
}

.panel-heading,
.card-heading {
  position: relative
}

.panel.no-back,
.card.no-back {
  background: none;
  border: 0;
  border-bottom: 1px solid darken($panel-border, 5%);
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin-bottom: 0px;
  padding-bottom: 10px;

  &.no-border {
    border: 0;
    border-color: transparent;
  }
}

.card.no-back > .card-heading
.panel.no-back > .panel-heading {
  background: none;
  border: 0;
  border-bottom: 0px solid darken($panel-border, 5%);
  font-size: 18px;
  font-weight: 300;
  color: lighten( $text-color, 11%);
  padding-top: 20px
}

.panel.no-back > .panel-heading a,
.card.no-back > .card-heading a {
  color: lighten( $text-color, 11%);
}

.panel.no-back > .panel-body
.card.no-back > .card-body {
  padding-top: 10px;
  padding-bottom: 0px;
}

.trans-icon {
  color: $brand-success;
  border-radius: 20px;
  padding: 8px 10px 6px;
  font-size: 17px;
  border: 1px solid $input-border;
  background: $brand-white;
}

.icn-search {
  background: url(/assets/img/icon-search.svg) 0 0 no-repeat;
  padding-left: 30px;
}

.table tbody tr td .progress {
  margin-bottom: 5px
}

.list-group.detail-group .list-group-item {
  border: 0;
  border-bottom: 1px solid darken($border-color, 6%);
}

.list-group.detail-group .list-group-item:first-child {
  border-top: 0;
}

$fixed-tool-panel-padder: 15px;

.fixed-tool-panel {
  position: relative;
  position: -webkit-sticky;
  position: sticky;
  width: 100%;
  right: 0;
  left: 0;
  background: darken($brand-light, 2%);
  min-height: 40px;
  opacity: .9;
  z-index: 900;
  top: 0;

  margin-bottom:  -1px;

  padding-top:0px;
  padding-bottom: 0px;
  padding-right: $fixed-tool-panel-padder;
  padding-left:  $fixed-tool-panel-padder;

  border-bottom: 1px solid $border-color;

  &:hover {
    opacity: 1
  }

  &:before,
  &:after {
    display: table;
    content: " ";
  }

  &:after {
    clear: both;
  }

}

.fixed-tool-panel {

  button {
    margin-left: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  ul.dropdown-menu button.btn-xs {
    margin-left: inherit;
    margin-top: -5px;
    margin-bottom: inherit;
  }

  .uib-datepicker button {
    margin: auto;
  }
  .uib-datepicker th,
  .uib-datepicker td {
    padding: 5px;
  }
}

/*

@media (min-width: $screen-sm) {

  .fixed-tool-panel {
    padding-left: $app-aside-width + $fixed-tool-panel-padder;
  }

}
@media (min-width: $screen-md) {

  .fixed-tool-panel.details-padder {
    padding-left: $app-aside-width + 200 + $fixed-tool-panel-padder;
  }

}

@media (max-width: $screen-sm-max) {

  .fixed-tool-panel.details-padder {
    padding-left: $app-aside-width +  $fixed-tool-panel-padder;
  }

}

@media (max-width: $screen-xs-max) {

  .fixed-tool-panel.details-padder {
    padding-left:   $fixed-tool-panel-padder;
  }

}

@media (max-width: $screen-xs-max) {
  .fixed-tool-panel {
    position: relative;
    top: 0px;
    opacity: 1;
  }
}*/

.details-screen {
  width: 100%;
}



// TODO Bootstrap don't has this var
@media (max-width: $screen-xxs-max ) {
  .hidden-xxs {
    display: none !important;
  }
}

@media (max-width: $screen-sm-max) {
  .fixed-sm {
    position: fixed;
  }

  .fixed-t-93 {
    top: 92px;
  }

  .hbox .details-screen .details-nav {
    height: auto
  }

  .details-screen.menu-show .details-info {
    padding-left: 200px;
    margin-right: -200px
  }

  .details-screen.menu-show .details-nav {
    display: block !important;
    position: fixed;
    top: 52px;
    overflow-y: scroll !important;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    height: 100%;
    width: 200px !important;
  }

  .details-screen.menu-show .details-nav-left {
    display: block !important;
    width: 200px;
    overflow: scroll !important;
    border: 0
  }

  .details-screen.menu-show .details-nav-left ul {
    padding-bottom: 100px !important;
  }
}

.fixed-top-search {
  height: 40px;
  background: darken($brand-light, 1%);
}

@media (min-width: $screen-md-min) {
  .top-search-padder {
    padding-bottom: 100px !important
  }

  .fixed-top-search-cont {
    position: absolute;
    top: 40px;
    left: 0px;
    width: 198px;
  }

  .fixed-top-search-cont ~ * {
    margin-top: 40px;
  }

  .fixed-top-search {
    height: 40px;
    width: 200px;
    top: 52px;
    z-index: 1006;
    position: fixed
  }
}

.constructor-row:before,
.constructor-row:after {
  display: table;
  content: " ";
}

.constructor-row:after {
  clear: both;
}

.constructor-row .btn.btn-icon {
  margin: 14px 0 10px 10px;
  height: 26px;
  width: 26px;
  font-size: .8em;
  padding: 4px !important;
  line-height: .8em;
  overflow: hidden;
  text-align: center
}

.constructor-row .btn.btn-icon i {
  top: -7px;
}

.constructor-control,
.constructor-row .input-group {
  margin-right: 10px;
  float: left;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.constructor-input {
  margin: 10px 10px 10px 0;
  float: left;
  width: 100%;
  display: inline-block;
}

.constructor-row i.auto-size {
  display: block;
  overflow: hidden;
  font-style: normal
}

.constructor-row .input-group .form-control {
  float: left;
}

.constructor-row .input-group .input-group-btn-vertical {
  width: 20px;
  float: right;
  display: table-cell
}

.constructor-row .constructor-level span.text {
  margin: 15px 10px 0 0px;
  display: block;
  overflow: hidden;
  float: left
}

.constructor-row select {
  overflow: hidden;
  white-space: nowrap
}


@media (max-width: $screen-xs-max) {
  .fixed-tab-panel .nav-tabs {
    left: 0px;
  }
}

/* new */


.fixed-left-fix {
  padding-left: 200px
}

@media (max-width: $screen-xs-max) {
  .fixed-left-fix {
    padding-left: 0px
  }
}



.table-big-link a {
  line-height: 140%;
  font-size: 16px;
  color: $brand-info !important
}

.table tr:hover .table-big-link a,
.table-big-link a:hover {
  color: darken($brand-info, 20%) !important
}

.table tbody tr {
  vertical-align: top
}

.table tbody td {
  overflow-wrap: break-word;
  word-break: normal;
  word-wrap: break-word;
}

.table thead {
  text-transform: uppercase !important;
  font-size: 11px;
}

.text-wrap {
  white-space: normal !important;
}


.table thead tr:hover .footable-sort-indicator {
  color: lighten($text-color, 33%)
}

.table thead tr th:hover .footable-sort-indicator {
  color: $text-color
}

.table thead tr th:hover {
  color: $text-color !important
}

.footable-sortable {
  position: relative;
  padding: 0 0 0 25px;
}

.footable > thead > tr > th.sort-indicator-center > span.footable-sort-indicator {
  display: block;
  position: absolute;
  top: 7px;
  left: 35%
}

@media (max-width: $screen-sm-min) {
  .hidden-768 {
    display: none !important;
  }
}

@media (max-width: $screen-md-min) {
  .hidden-992 {
    display: none !important;
  }
}

@media (max-width: $screen-lg-min) {
  .hidden-1200 {
    display: none !important;
  }
}

@media (max-width: 1440px) {
  .hidden-1440 {
    display: none !important;
  }
}

@media (max-width: 1600px) {
  .hidden-1600 {
    display: none !important;
  }
}

.visible-768,
.visible-992,
.visible-1200,
.visible-1400,
.visible-1440,
.visible-1600 {
  display: none !important
}

@media (max-width: $screen-sm-min) {
  .visible-768 {
    display: inherit !important;
  }
}

@media (max-width: $screen-md-min) {
  .visible-992 {
    display: inherit !important;
  }
}

@media (max-width: $screen-lg-min) {
  .visible-1200 {
    display: inherit !important;
  }
}

@media (max-width: 1440px) {
  .visible-1440 {
    display: inherit !important;
  }
}

@media (max-width: 1600px) {
  .visible-1600 {
    display: inherit !important;
  }
}

@media (max-width: 706px) {
  .hidden-phone {
    display: none !important;
  }
}

@media (max-width: 1250px) {
  .hidden-tablet {
    display: none !important;
  }
}

@media (max-width: 1666px) {
  .hidden-screen {
    display: none !important;
  }
}

.f-s-11px {
  font-size: 11px !important;
}

.f-s-12px {
  font-size: 12px !important;
}

.f-s-13px {
  font-size: 13px !important;
}

.f-s-14px {
  font-size: 14px !important;
}

.f-s-24px {
  font-size: 24px !important;
}

.f-s-26px {
  font-size: 26px !important;
}

.f-s-28px {
  font-size: 28px !important;
}

.f-s-30px {
  font-size: 30px !important;
}

.f-s-32px {
  font-size: 32px !important;
}

.f-s-34px {
  font-size: 34px !important;
}

.addition-description {
  display: inline-block;
  color: lighten($text-color, 33%);
  font-size: 12px;
  min-width: 200px;
  margin: 0 10px 5px 0
}

.table-customers .addition-description {
  min-width: 120px;
}

.table-customers .descr-block {
  margin-right: -600px;
  text-align: left;
  margin-top: 8px;
}

.addition-value {
  display: inline-block;
  margin: 0 0 5px 0
}

.icon-segment {
  height: 30px;
  width: 30px;
  display: block;
  background: url(/assets/img/icons-for-segments.svg);
  background-repeat: no-repeat;
  background-position: 0 0;
}

.icon-segment.i0 {
  background-position: 0 0;
}

tr:hover .icon-segment.i0 {
  background-position: -30px 0;
}

.icon-segment.i1 {
  background-position: 0 -30px;
}

tr:hover .icon-segment.i1 {
  background-position: -30px -30px;
}

.icon-segment.i2 {
  background-position: 0 -60px;
}

tr:hover .icon-segment.i2 {
  background-position: -30px -60px;
}

.icon-segment.i3 {
  background-position: 0 -90px;
}

tr:hover .icon-segment.i3 {
  background-position: -30px -90px;
}

.icon-customer {
  height: 30px;
  width: 30px;
  display: block;
  background: url(/assets/img/icons-for-segments.svg);
  background-repeat: no-repeat;
  background-position: 0 0;
}

.icon-customer.i0 {
  background-position: 0 0;
}

tr:hover .icon-customer.i0 {
  background-position: -30px 0;
}

.icon-customer.i1 {
  background-position: 0 -120px;
}

tr:hover .icon-customer.i1 {
  background-position: -30px -120px;
}

.icon-customer.i2 {
  background-position: 0 -150px;
}

tr:hover .icon-customer.i2 {
  background-position: -30px -150px;
}

.icon-customer.i3 {
  background-position: 0 -180px;
}

tr:hover .icon-customer.i3 {
  background-position: -30px -180px;
}

.icon-customer.i4 {
  background-position: 0 -210px;
}

tr:hover .icon-customer.i4 {
  background-position: -30px -210px;
}

.icon-customer.i5 {
  background-position: 0 -240px;
}

tr:hover .icon-customer.i5 {
  background-position: -30px -240px;
}

.icon-customer.i6 {
  background-position: 0 -270px;
}

tr:hover .icon-customer.i6 {
  background-position: -30px -270px;
}

.icon-customer.i7 {
  background-position: 0 -452px;
}

tr:hover .icon-customer.i7 {
  background-position: -30px -452px;
}

.icon-customer.i8 {
  background-position: 0px -487px;
}

tr:hover .icon-customer.i8 {
  background-position: -30px -487px;
}

.icon-ggroup {
  height: 30px;
  width: 30px;
  display: block;
  background: url(/assets/img/icons-for-segments.svg);
  background-repeat: no-repeat;
  background-position: 0 0;
}

.icon-ggroup.i0 {
  background-position: 0 0;
}

tr:hover .icon-ggroup.i0 {
  background-position: -30px 0;
}

.icon-ggroup.i1 {
  background-position: 0 -300px;
}

tr:hover .icon-ggroup.i1 {
  background-position: -30px -300px;
}

.icon-mech {
  height: 30px;
  width: 30px;
  display: block;
  background: url(/assets/img/icons-for-segments.svg);
  background-repeat: no-repeat;
  background-position: 0 0;
}

.icon-mech.i0 {
  background-position: 0 0;
}

tr:hover .icon-mech.i0 {
  background-position: -30px 0;
}

.icon-mech.i1 {
  background-position: 0 -330px;
}

tr:hover .icon-mech.i1 {
  background-position: -30px -330px;
}

.icon-camp {
  height: 30px;
  width: 30px;
  display: block;
  background: url(/assets/img/icons-for-segments.svg);
  background-repeat: no-repeat;
  background-position: 0 0;
}

.icon-camp.i0 {
  background-position: 0 0px;
}

tr:hover .icon-camp.i0 {
  background-position: -30px 0px;
}

.icon-camp.i1 {
  background-position: 0 -360px;
}

tr:hover .icon-camp.i1 {
  background-position: -30px -360px;
}

.icon-camp.i2 {
  background-position: 0 -390px;
}

tr:hover .icon-camp.i2 {
  background-position: -30px -390px;
}

.icon-camp.i3 {
  background-position: 0 -420px;
}

tr:hover .icon-camp.i3 {
  background-position: -30px -420px;
}

html, body, .app:before {
  background: $brand-white
}

@media (min-width: $screen-lg-min) {
  .pull-right-lg {
    float: right !important;
  }
}

.shkala {
  position: relative;
  padding: 0 10px
}

.shkala .line0,
.shkala .line1,
.shkala .line2,
.shkala .line3,
.shkala .line4,
.shkala .line5 {
  position: absolute;
  top: 5px;
  height: 12px;
  width: 1px;
  background: lighten($brand-light, 5%);
}

.shkala .line0 {
  left: 0%;
}

.shkala .line1 {
  left: 20%;
}

.shkala .line2 {
  left: 40%;
}

.shkala .line3 {
  left: 60%;
}

.shkala .line4 {
  left: 80%;
}

.shkala .line5 {
  left: 100%;
}

.shaka-correction {
  position: relative;
  padding-top: 8px;
}

.relative {
  position: relative
}

/*
.btn, .btn *, .input-group, .input-group *,
.dropdown-menu, .dropdown-menu *, input, .nav, .nav-tab, .nav *, select, .chosen-container, .chosen-container * { border-radius:0 !important}
*/
.w100p {
  width: 100% !important
}

.right-padding-null td, .right-padding-null th {
  padding-right: 0 !important
}

.footable.breakpoint > tbody > tr.footable-detail-show > td > span.footable-toggle:before {
  content: "\e00e";
}

.footable.breakpoint > tbody > tr > td > span.footable-toggle:before {
  content: "\e00f";
}

/*

@font-face {
  font-family: 'footable';
  src: url('./../../fonts/footable.eot');
  src: url('./../../fonts/footable.eot?#iefix') format('embedded-opentype'), url('./../../fonts/footable.woff') format('woff'), url('./../../fonts/footable.ttf') format('truetype'), url('./../../fonts/footable.svg#footable') format('svg');
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: 'footable';
    src: url('./../../fonts/footable.svg#footable') format('svg');
    font-weight: normal;
    font-style: normal;
  }
}
*/

.dropdown-details > tbody > tr.dropdown-details-show > td {
  border-bottom: none;
}

.dropdown-details > tbody > tr:hover:not(.dropdown-details-row) {
  cursor: pointer;
}

.dropdown-details > tbody > tr > td > span.dropdown-details-toggle {
  display: inline-block;
  font-family: 'footable';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  padding-right: 5px;
  font-size: 14px;
  color: lighten($text-color, 33%);
}

.dropdown-details > tbody > tr > td > span.dropdown-details-toggle:before {
  content: "\e00f";
}

.dropdown-details > tbody > tr.dropdown-details-show > td > span.dropdown-details-toggle:before {
  content: "\e00e";
}

.dropdown-details > tbody > tr td {
  border: 0
}

.dropdown-details > tbody > tr {
  border-top: 1px solid darken($border-color, 6%);
}

.dropdown-details > tbody {
  border-bottom: 1px solid darken($border-color, 6%);
}

.dropdown-details > tbody > tr.dropdown-details-row {
  border-top: 0;
  display: none;
}

.dropdown-details > tbody > tr.dropdown-details-row > td {
  padding-right: 10px !important
}

.dropdown-details > tbody > tr > td > .constructor-row .btn-icon {
  margin-left: 10px;
  margin-right: 0px;
}

.dropdown-details > tbody > tr.dropdown-details-row:hover,
tr.footable-row-detail:hover td {
  background: $brand-white !important
}

tr.footable-row-detail td {
  border-top: 0 !important
}

.dropdown-details .constructor-control select {
  width: 100%;
  max-width: 200px;
  height: 32px;
  overflow: hidden;
  white-space: nowrap
}

.visible-on-tr-hover {
  display: none
}

tr:hover .visible-on-tr-hover {
  display: inherit
}

.crit-left-shift {
  margin-left: 88px
}

@media (min-width: $screen-md-min) {
  .crit-left-shift2 {
    margin-left: 88px
  }
}


.i-checks input:checked + i {
  border-color: $link-color;
}

.i-checks input:checked + i:before {
  background-color: $link-color;
}


.rfm-c1 {
  background-color: #91c741 !important;
  color: $brand-white
}

.rfm-c2 {
  background-color: #0a99f1 !important;
  color: $brand-white
}

.rfm-c3 {
  background-color: #ff4001 !important;
  color: $brand-white
}

.rfm-c4 {
  background-color: #13b28a !important;
  color: $brand-white
}

.rfm-c5 {
  background-color: #0149a2 !important;
  color: $brand-white
}

.rfm-c6 {
  background-color: #ffcc03 !important;
  color: $brand-white
}

.rfm-c7 {
  background-color: #ff00ff !important;
  color: $brand-white
}

.rfm-c8 {
  background-color: #c1272d !important;
  color: $brand-white
}

.rfm-c9 {
  background-color: #cde82b !important;
  color: $brand-white
}

.rfm-c10 {
  background-color: #009245 !important;
  color: $brand-white
}

.rfm-c11 {
  background-color: #f5e82e !important;
  color: $brand-white
}

.rfm-c12 {
  background-color: #7925cd !important;
  color: $brand-white
}

.rfm-c13 {
  background-color: #0071bc !important;
  color: $brand-white
}

.rfm-c14 {
  background-color: #9e005d !important;
  color: $brand-white
}

.rfm-c15 {
  background-color: #754c24 !important;
  color: $brand-white
}

.rfm-c16 {
  background-color: #a67c52 !important;
  color: $brand-white
}

.rfm-c17 {
  background-color: #b3b3b3 !important;
  color: $brand-white
}

.rfm-c18 {
  background-color: #998675 !important;
  color: $brand-white
}

.rfm-c19 {
  background-color: #0000ff !important;
  color: $brand-white
}

.rfm-c20 {
  background-color: #2e3192 !important;
  color: $brand-white
}

.rfm-t1 {
  color: #91c741;
}

.rfm-t2 {
  color: #0a99f1;
}

.rfm-t3 {
  color: #ff4001;
}

.rfm-t4 {
  color: #13b28a;
}

.rfm-t5 {
  color: #0149a2;
}

.rfm-t6 {
  color: #ffcc03;
}

.rfm-t7 {
  color: #ff00ff;
}

.rfm-t8 {
  color: #c1272d;
}

.rfm-t9 {
  color: #cde82b;
}

.rfm-t10 {
  color: #009245;
}

.rfm-t11 {
  color: #f5e82e;
}

.rfm-t12 {
  color: #7925cd;
}

.rfm-t13 {
  color: #0071bc;
}

.rfm-t14 {
  color: #9e005d;
}

.rfm-t15 {
  color: #754c24;
}

.rfm-t16 {
  color: #a67c52;
}

.rfm-t17 {
  color: #b3b3b3;
}

.rfm-t18 {
  color: #998675;
}

.rfm-t19 {
  color: #0000ff;
}

.rfm-t20 {
  color: #2e3192;
}

.i-checks input.indeterminate + i {
  border-color: $link-color;
}

.i-checks input.indeterminate + i:before {
  background-color: $link-color;
  left: 4px;
  top: 7px;
  width: 10px;
  height: 4px;
}
/*
.clearfix {
  width: 100%;
}*/

.clearfix:before,
.clearfix:after {
  display: table;
  content: " ";
}

.clearfix:after {
  clear: both;
}

.row > .clearfix {
  width: 100%;
}

.popover {
  max-width: 500px;
}

ul.ivh-treeview li.ivh-treeview-node-collapsed ul.ivh-treeview {
  display: none;
}

ul.ivh-treeview .ivh-treeview-twistie-leaf,
ul.ivh-treeview .ivh-treeview-twistie-collapsed {
  display: none;
}

ul.ivh-treeview .ivh-treeview-node-collapsed .ivh-treeview-twistie-collapsed {
  display: inline;
}

ul.ivh-treeview .ivh-treeview-node-collapsed .ivh-treeview-twistie-expanded {
  display: none;
}

ul.ivh-treeview li.ivh-treeview-node-leaf .ivh-treeview-twistie-leaf {
  display: inline;
}

ul.ivh-treeview li.ivh-treeview-node-leaf .ivh-treeview-twistie-expanded,
ul.ivh-treeview li.ivh-treeview-node-leaf .ivh-treeview-twistie-collapsed {
  display: none;
}

ul.ivh-treeview {
  list-style-type: none;
  padding-left: 0;
}

ul.ivh-treeview ul.ivh-treeview {
  padding-left: 0px;
}

ul.ivh-treeview .ivh-treeview-toggle {
  cursor: pointer;
}

.ivh-tree-container {
}

.ivh-treeview {
  margin: 0;
  padding: 0;
}

.ivh-treeview ul {
  margin: 0;
  padding: 0;;
}

.ivh-treeview li {
  margin: 0;
  padding: 0 0;
  position: relative;
  min-height: 30px
}

.abs-left {
  position: absolute;
  left: 15px;
  top: 10px;
  margin: 0 !important;
}

.ivh-treeview .ivh-treeview-twistie {
  margin: 0 20px 0 -40px;
  display: inline-block;
  width: 20px;
}

.ivh-treeview .ivh-treeview-twistie .ivh-treeview-twistie-collapsed,
.ivh-treeview .ivh-treeview-twistie .ivh-treeview-twistie-expanded {
  background: url('/assets/img/w20.gif') 50% 50% no-repeat
}

.ivh-treeview .checkrow:hover {
  background: $hover-bg !important;
}

.ivh-treeview .checkrow:hover .ivh-treeview-twistie-collapsed,
.ivh-treeview .checkrow:hover .ivh-treeview-twistie-expanded {
  background: $hover-bg !important;
}

.ivh-quantity {
  margin: 0 20px;
  padding: 10px 0;
  display: inline-block;
  min-width: 15%;
  text-align: right;
  float: right
}

.ivh-value {
  margin-left: 20px;
  padding: 10px 0;
  display: inline-block;
  min-width: 5%;
  text-align: right;
  float: right
}

.ivh-treeview-twistie {
  color: lighten($text-color, 33%)
}

.checkrow {
  padding: 0;
  z-index: 1
}

.ivh-treeview-node-label {
  margin: 0 0 0 0;
  display: inline-block;
}

.ivh-treeview-node-click-open {
  display: none;
}

.ivh-treeview-node-click-action {
  display: none;
}

.ivh-treeview-node-click-action-item {
  display: none;
}

.ivh-treeview-node-pad {
  display: block;
  position: relative;
  padding: 10px 0 10px 50px; /*border-left:1px solid darken($border-color, 6%);*/
  background: url('/assets/img/greyhline.gif') 0 18px no-repeat;
  z-index: -1
}

.ivh-treeview-node-expanded > div > div > .ivh-treeview-node-label > .ivh-treeview-node-text > .ivh-treeview-node-pad {
  background: url('/assets/img/greytline.gif') 0 18px no-repeat;
}

.ivh-treeview-node-text {
  display: block;
  position: relative;
  width: 78%;
  z-index: 2
}

@media (max-width: $screen-md-min) {
  .ivh-treeview-node-text {
    width: 98%;
  }
}

.ivh-leaficon {
  display: inline-block;
  margin: 0 0 0 -20px;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  -webkit-font-smoothing: antialiased;
  text-rendering: auto;
  -moz-osx-font-smoothing: grayscale;
  color: $input-border;
  width: 20px;
}

.ivh-leaficon:before {
  content: "\f02b";
}

.ivh-treeview-node-expanded > div > div > .ivh-treeview-node-label > .ivh-treeview-node-text > .ivh-treeview-node-pad .ivh-leaficon,
.ivh-treeview-node-collapsed > div > div > .ivh-treeview-node-label > .ivh-treeview-node-text > .ivh-treeview-node-pad .ivh-leaficon {
  color: $brand-success;
}

.ivh-treeview-node-expanded > div > div > .ivh-treeview-node-label > .ivh-treeview-node-text > .ivh-treeview-node-pad .ivh-leaficon:before,
.ivh-treeview-node-collapsed > div > div > .ivh-treeview-node-label > .ivh-treeview-node-text > .ivh-treeview-node-pad .ivh-leaficon:before {
  content: "\f02c";
}

.ivh-treeview li {
  background: url('/assets/img/greyvline.gif') repeat-y
}

.ivh-treeview li:last-child {
  background-repeat: no-repeat !important;
}

.ivh-treeview li {
  background-position: 45px 0
}

.ivh-treeview li li {
  background-position: 60px 0
}

.ivh-treeview li li li {
  background-position: 75px 0
}

.ivh-treeview li li li li {
  background-position: 90px 0
}

.ivh-treeview li li li li li {
  background-position: 105px 0
}

.ivh-treeview li li li li li li {
  background-position: 120px 0
}

.ivh-treeview li li li li li li li {
  background-position: 135px 0
}

.ivh-treeview li li li li li li li li {
  background-position: 150px 0
}

.ivh-treeview li li li li li li li li li {
  background-position: 165px 0
}

.ivh-treeview li li li li li li li li li li {
  background-position: 180px 0
}

.ivh-treeview li li li li li li li li li li li {
  background-position: 195px 0
}

.ivh-treeview li li li li li li li li li li li li {
  background-position: 210px 0
}

.ivh-treeview li li li li li li li li li li li li li {
  background-position: 225px 0
}

.ivh-treeview li li li li li li li li li li li li li li {
  background-position: 240px 0
}

.ivh-treeview li li li li li li li li li li li li li li li {
  background-position: 255px 0
}

.ivh-treeview li li li li li li li li li li li li li li li li {
  background-position: 270px 0
}

.ivh-treeview li li li li li li li li li li li li li li li li li {
  background-position: 285px 0
}

.ivh-treeview li li li li li li li li li li li li li li li li li li {
  background-position: 300px 0
}

.ivh-treeview li li li li li li li li li li li li li li li li li li li {
  background-position: 315px 0
}

.ivh-treeview li li li li li li li li li li li li li li li li li li li li {
  background-position: 330px 0
}

.ivh-treeview li li li li li li li li li li li li li li li li li li li li li {
  background-position: 345px 0
}

.ivh-treeview li .ivh-treeview-node-pad {
  margin-left: 45px;
}

.ivh-treeview li li .ivh-treeview-node-pad {
  margin-left: 60px;
}

.ivh-treeview li li li .ivh-treeview-node-pad {
  margin-left: 75px;
}

.ivh-treeview li li li li .ivh-treeview-node-pad {
  margin-left: 90px;
}

.ivh-treeview li li li li li .ivh-treeview-node-pad {
  margin-left: 105px;
}

.ivh-treeview li li li li li li .ivh-treeview-node-pad {
  margin-left: 120px;
}

.ivh-treeview li li li li li li li .ivh-treeview-node-pad {
  margin-left: 135px;
}

.ivh-treeview li li li li li li li li .ivh-treeview-node-pad {
  margin-left: 150px;
}

.ivh-treeview li li li li li li li li li .ivh-treeview-node-pad {
  margin-left: 165px;
}

.ivh-treeview li li li li li li li li li li .ivh-treeview-node-pad {
  margin-left: 180px;
}

.ivh-treeview li li li li li li li li li li li .ivh-treeview-node-pad {
  margin-left: 195px;
}

.ivh-treeview li li li li li li li li li li li li .ivh-treeview-node-pad {
  margin-left: 210px;
}

.ivh-treeview li li li li li li li li li li li li li .ivh-treeview-node-pad {
  margin-left: 225px;
}

.ivh-treeview li li li li li li li li li li li li li li .ivh-treeview-node-pad {
  margin-left: 240px;
}

.ivh-treeview li li li li li li li li li li li li li li li .ivh-treeview-node-pad {
  margin-left: 255px;
}

.ivh-treeview li li li li li li li li li li li li li li li li .ivh-treeview-node-pad {
  margin-left: 270px;
}

.ivh-treeview li li li li li li li li li li li li li li li li li .ivh-treeview-node-pad {
  margin-left: 285px;
}

.ivh-treeview li li li li li li li li li li li li li li li li li li .ivh-treeview-node-pad {
  margin-left: 300px;
}

.ivh-treeview li li li li li li li li li li li li li li li li li li li .ivh-treeview-node-pad {
  margin-left: 315px;
}

.ivh-treeview li li li li li li li li li li li li li li li li li li li li .ivh-treeview-node-pad {
  margin-left: 330px;
}

.ivh-treeview li li li li li li li li li li li li li li li li li li li li li .ivh-treeview-node-pad {
  margin-left: 345px;
}

.ivh-treeview .checkbox {
  z-index: 1041
}

/*.ivh-modalcanvas {max-height:800px; overflow-y:scroll}*/

.ivh-treeview-node-label {
  display: block;
}

.no-side-borders .tab-container .tab-content {
  border-left: 0;
  border-right: 0
}

.no-side-borders .tab-container .nav.nav-tabs > li:first-child a {
  border-left: 0;
  border-radius: 0 4px 0 0
}

.dropdown-details-ul {
  margin: 0 0px;
  list-style: none;
  padding: 0;
  border-top: 1px solid darken($border-color, 6%)
}

.dropdown-details-ul > li {
  padding: 5px 20px;
  border-bottom: 1px solid darken($border-color, 6%)
}

.dropdown-details-ul span.dropdown-details-toggle {
  display: inline-block;
  font-family: 'footable';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  padding-right: 5px;
  font-size: 14px;
  color: lighten($text-color, 33%);
  cursor: pointer
}

.dropdown-details-ul li span.dropdown-details-toggle:before {
  content: "\e00f";
}

.dropdown-details-ul .dropdown-details-show span.dropdown-details-toggle:before {
  content: "\e00e";
}

.bonus-icon {
  color: $text-orange;
}

.dropdown-details-ul .event-detail {
  display: none
}

.dropdown-details-ul .dropdown-details-show .event-detail {
  display: block
}


.dropdown-details-ul .bonus-detail {
  display: none;
  margin-left: 90px;
  margin-top: 10px;
}

.dropdown-details-ul .dropdown-details-show .bonus-detail {
  display: block
}

.modal-content {
  border-radius: 0;
  margin-left: 0px !important;
  margin-right:0px !important;
}

.modal-lg {
  width: 100%;
  margin-left: auto !important;
  margin-right: auto !important
}

@media (max-width: $screen-md-min) {
  .modal-content {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}

@media (max-width: 460px) {
  .modal-content {
    margin: 0px !important;
  }

  .modal-dialog {
    margin-top: 0px !important
  }
}
