$font-size-base: 14px;
$pre-bg:                      #f5f5f5 !default;
$pre-border-color:            #ccc !default;
$popover-fallback-border-color:       #ccc !default;

$popover-arrow-outer-width:           ($popover-arrow-width + 1) !default;
$popover-arrow-outer-fallback-color:  darken($popover-fallback-border-color, 20%) !default;

$caret-width-base:          4px !default;
$cursor-disabled:                not-allowed !default;
$grid-float-breakpoint:     $screen-sm-min !default;
$padding-base-vertical:     6px !default;
$padding-base-horizontal:   12px !default;
$padding-small-vertical:    5px !default;
$padding-small-horizontal:  10px !default;
$font-size-small:         ceil(($font-size-base * .85)) !default; // ~12px
$border-radius-small:       3px !default;
$input-border-radius-small:      $border-radius-small !default;


$padding-large-vertical:    10px !default;
$padding-large-horizontal:  16px !default;
$font-size-large:         ceil(($font-size-base * 1.25)) !default; // ~18px
$border-radius-large:       6px !default;
$input-border-radius-large:      $border-radius-large !default;


@mixin reset-filter() {
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}

@mixin nav-divider($color: #e5e5e5) {
  height: 1px;
  margin: (($line-height-computed / 2) - 1) 0;
  overflow: hidden;
  background-color: $color;
}


// Blocks of code
pre {
  font-family: $font-family-monospace;
  display: block;
  padding: (($line-height-computed - 1) / 2);
  margin: 0 0 ($line-height-computed / 2);
  font-size: ($font-size-base - 1); // 14px to 13px
  line-height: $line-height-base;
  color: $pre-color;
  word-break: break-all;
  word-wrap: break-word;
  background-color: $pre-bg;
  border: 1px solid $pre-border-color;
  border-radius: $border-radius-base;
}

// Popovers

.popover-title {
  @extend .popover-header;
}

.popover-content {
  @extend .popover-body;
}

.popover {
  // Arrows
  // .arrow is outer, .arrow:after is inner
  // Offset the popover to account for the popover arrow
  &.top { margin-top: -$popover-arrow-width; }
  &.right { margin-left: $popover-arrow-width; }
  &.bottom { margin-top: $popover-arrow-width; }
  &.left { margin-left: -$popover-arrow-width; }

  > .arrow {
    border-width: $popover-arrow-outer-width;

    &,
    &:after {
      position: absolute;
      display: block;
      width: 0;
      height: 0;
      border-color: transparent;
      border-style: solid;
    }

    &:after {
      content: "";
      border-width: $popover-arrow-width;
    }
  }

  &.top > .arrow {
    bottom: -$popover-arrow-outer-width;
    left: 50%;
    margin-left: -$popover-arrow-outer-width;
    border-top-color: $popover-arrow-outer-fallback-color; // IE8 fallback
    border-top-color: $popover-arrow-outer-color;
    border-bottom-width: 0;
    &:after {
      bottom: 1px;
      margin-left: -$popover-arrow-width;
      content: " ";
      border-top-color: $popover-arrow-color;
      border-bottom-width: 0;
    }
  }
  &.right > .arrow {
    top: 50%;
    left: -$popover-arrow-outer-width;
    margin-top: -$popover-arrow-outer-width;
    border-right-color: $popover-arrow-outer-fallback-color; // IE8 fallback
    border-right-color: $popover-arrow-outer-color;
    border-left-width: 0;
    &:after {
      bottom: -$popover-arrow-width;
      left: 1px;
      content: " ";
      border-right-color: $popover-arrow-color;
      border-left-width: 0;
    }
  }
  &.bottom > .arrow {
    top: -$popover-arrow-outer-width;
    left: 50%;
    margin-left: -$popover-arrow-outer-width;
    border-top-width: 0;
    border-bottom-color: $popover-arrow-outer-fallback-color; // IE8 fallback
    border-bottom-color: $popover-arrow-outer-color;
    &:after {
      top: 1px;
      margin-left: -$popover-arrow-width;
      content: " ";
      border-top-width: 0;
      border-bottom-color: $popover-arrow-color;
    }
  }

  &.left > .arrow {
    top: 50%;
    right: -$popover-arrow-outer-width;
    margin-top: -$popover-arrow-outer-width;
    border-right-width: 0;
    border-left-color: $popover-arrow-outer-fallback-color; // IE8 fallback
    border-left-color: $popover-arrow-outer-color;
    &:after {
      right: 1px;
      bottom: -$popover-arrow-width;
      content: " ";
      border-right-width: 0;
      border-left-color: $popover-arrow-color;
    }
  }
}

// PANELs

.panel {
  @extend .card;
}

.panel-heading {
  @extend .card-header;
}

.panel-body {
  @extend .card-body;
}

.panel-footer {
  @extend .card-footer;
}

// Collapse

.fade {
  opacity: 0;
  &.in,
  &.show
  {
    opacity: 1;
  }
}

.collapse {
  display: none;
  &.in      { display: block; }
}


//---- DROPDOWN



// Dropdown arrow/caret

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: $caret-width-base dashed;
  border-top: $caret-width-base solid \9; // IE8
  border-right: $caret-width-base solid transparent;
  border-left: $caret-width-base solid transparent;
}

.dropdown-menu {

  // Aligns the dropdown menu to right
  //
  // Deprecated as of 3.1.0 in favor of `.dropdown-menu-[dir]`
  &.pull-right {
    right: 0;
    left: auto;
  }

  // Dividers (basically an hr) within the dropdown
  .divider {
    @include nav-divider($dropdown-divider-bg);
  }

  // Links within the dropdown menu
  > li > a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: $line-height-base;
    color: $dropdown-link-color;
    white-space: nowrap; // prevent links from randomly breaking onto new lines

    &:hover,
    &:focus {
      color: $dropdown-link-hover-color;
      text-decoration: none;
      background-color: $dropdown-link-hover-bg;
    }
  }
}

.dropdown-toggle:focus {
  outline: 0;
}

.dropdown-menu > .active > a {
  &,
  &:hover,
  &:focus {
    color: $dropdown-link-active-color;
    text-decoration: none;
    background-color: $dropdown-link-active-bg;
    outline: 0;
  }
}

.dropdown-menu > .disabled > a {
  &,
  &:hover,
  &:focus {
    color: $dropdown-link-disabled-color;
  }

  // Nuke hover/focus effects
  &:hover,
  &:focus {
    text-decoration: none;
    cursor: $cursor-disabled;
    background-color: transparent;
    background-image: none; // Remove CSS gradient
    @include reset-filter;
  }
}

.open {
  // Show the menu
  > .dropdown-menu {
    display: block;
  }

  // Remove the outline when :focus is triggered
  > a {
    outline: 0;
  }
}

.dropdown-menu-right {
  right: 0;
  left: auto; // Reset the default from `.dropdown-menu`
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ($zindex-dropdown - 10);
}

.pull-right > .dropdown-menu {
  right: 0;
  left: auto;
}

.navbar-fixed-bottom .dropdown {
  // Reverse the caret
  .caret {
    content: "";
    border-top: 0;
    border-bottom: $caret-width-base dashed;
    border-bottom: $caret-width-base solid \9; // IE8
  }
  // Different positioning for bottom up menu
  .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-bottom: 2px;
  }
}

@media (min-width: $grid-float-breakpoint) {
  .navbar-right {
    .dropdown-menu {
      right: 0; left: auto;
    }
    // Necessary for overrides of the default right aligned menu.
    // Will remove come v4 in all likelihood.
    .dropdown-menu-left {
      left: 0; right: auto;
    }
  }
}

//
// Input groups
// --------------------------------------------------

// Base styles
// -------------------------
.input-group {
  position: relative; // For dropdowns
  display: table;
  border-collapse: separate; // prevent input groups from inheriting border styles from table cells when placed within a table
  border-spacing: 0px;

  // Undo padding and float of grid classes
  &[class*="col-"] {
    float: none;
    padding-right: 0;
    padding-left: 0;
  }

  .form-control {
    // Ensure that the input is always above the *appended* addon button for
    // proper border colors.
    position: relative;
    z-index: 2;

    // IE9 fubars the placeholder attribute in text inputs and the arrows on
    // select elements in input groups. To fix it, we float the input. Details:
    // https://github.com/twbs/bootstrap/issues/11561#issuecomment-28936855
    float: left;

    width: 100%;
    margin-bottom: 0;

    &:focus {
      z-index: 3;
    }
  }
}

// Display as table-cell
// -------------------------
.input-group-addon,
.input-group-btn,
.input-group .form-control {
  display: table-cell;

  &:not(:first-child):not(:last-child) {
    border-radius: 0;
  }
}
// Addon and addon wrapper for buttons
.input-group-addon,
.input-group-btn {
  width: 1%;
  white-space: nowrap;
  vertical-align: middle; // Match the inputs
}

// Text input groups
// -------------------------
.input-group-addon {
  padding: $padding-base-vertical $padding-base-horizontal;
  font-size: $font-size-base;
  font-weight: 400;
  line-height: 1;
  color: $input-color;
  text-align: center;
  background-color: $input-group-addon-bg;
  border: 1px solid $input-group-addon-border-color;
  border-radius: $input-border-radius;

  // Sizing
  &.input-sm {
    padding: $padding-small-vertical $padding-small-horizontal;
    font-size: $font-size-small;
    border-radius: $input-border-radius-small;
  }
  &.input-lg {
    padding: $padding-large-vertical $padding-large-horizontal;
    font-size: $font-size-large;
    border-radius: $input-border-radius-large;
  }

  // Nuke default margins from checkboxes and radios to vertically center within.
  input[type="radio"],
  input[type="checkbox"] {
    margin-top: 0;
  }
}

// Reset rounded corners
.input-group .form-control:first-child,
.input-group-addon:first-child,
.input-group-btn:first-child > .btn,
.input-group-btn:first-child > .btn-group > .btn,
.input-group-btn:first-child > .dropdown-toggle,
.input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group-btn:last-child > .btn-group:not(:last-child) > .btn {
  @include border-right-radius(0);
}
.input-group-addon:first-child {
  border-right: 0;
}
.input-group .form-control:last-child,
.input-group-addon:last-child,
.input-group-btn:last-child > .btn,
.input-group-btn:last-child > .btn-group > .btn,
.input-group-btn:last-child > .dropdown-toggle,
.input-group-btn:first-child > .btn:not(:first-child),
.input-group-btn:first-child > .btn-group:not(:first-child) > .btn {
  @include border-left-radius(0);
}
.input-group-addon:last-child {
  border-left: 0;
}

// Button input groups
// -------------------------
.input-group-btn {
  position: relative;
  // Jankily prevent input button groups from wrapping with `white-space` and
  // `font-size` in combination with `inline-block` on buttons.
  font-size: 0;
  white-space: nowrap;

  // Negative margin for spacing, position for bringing hovered/focused/actived
  // element above the siblings.
  > .btn {
    position: relative;
    + .btn {
      margin-left: -1px;
    }
    // Bring the "active" button to the front
    &:hover,
    &:focus,
    &:active {
      z-index: 2;
    }
  }

  // Negative margin to only have a 1px border between the two
  &:first-child {
    > .btn,
    > .btn-group {
      margin-right: -1px;
    }
  }
  &:last-child {
    > .btn,
    > .btn-group {
      z-index: 2;
      margin-left: -1px;
    }
  }
}
