/*

*/

$brand-primary:         #305bc0;
$brand-info:            #23b7e5;
$brand-success:         #27c24c;
$brand-warning:         #fad733;
$brand-danger:          #c9302c;
$brand-light:           #f5f5f5;
$brand-dark:            #515151;
$brand-black:           #363636;

$brand-white:           #ffffff;

$body-bg:               lighten($brand-light, 1%);
$text-color:            #333333;
$text-orange:           #f5962e;
$text-muted:            lighten($text-color, 25%);

$font-family-sans-serif:  Museo,'Helvetica Neue',Helvetica,Arial,sans-serif;
$font-family-serif:       Georgia, "Times New Roman", Times, serif;
$font-family-base:        $font-family-sans-serif;

/*
$font-size-base:          1rem;
$font-size-base-px:       14px;
$font-size-lg:            ceil($font-size-base * 1.23); // ~20px
$font-size-md:            ceil($font-size-base * 1.14); // ~16px
$font-size-sm:            ceil($font-size-base * 0.92); // ~13px
$font-size-xs:            ceil($font-size-base * 0.85); // ~12px
*/
$font-size-base-px:       14px;
/*
$font-size-base:          1rem !default;

$font-size-lg:            ceil($font-size-base-px * 1.23); // ~20px
$font-size-md:            ceil($font-size-base-px * 1.14); // ~16px
$font-size-sm:            ceil($font-size-base-px * 0.92); // ~13px
$font-size-xs:            ceil($font-size-base-px * 0.85); // ~12px
*/
$link-color:              #305bc0;
$link-hover-color:        #213e83;

$border-radius-base:      4px;
$border-color:		  	  darken($brand-light, 9.5%);

$line-height-base:        1.42858; // 20/14
$line-height-computed:    floor($font-size-base-px * $line-height-base); // ~20px
$icon-css-prefix:         i;



//
// Images
// -------------------------

$img-bg-loading:  '/assets/img/bg-body.svg';
$logo-img:        '/assets/img/icons.svg';
$icon-logo:       '/assets/img/icon-logo.svg';
$bg-body-dummy:   '/assets/img/bg-body.svg';
$bg-body-legacy:  '/assets/img/bg-body_legacy.svg';
$start-logo:      '/assets/img/login_logo.svg';


// Buttons
// -------------------------

$btn-default-color:              $text-color;
$btn-default-bg:                 lighten($brand-light, 5%);
$btn-default-border:             $border-color;
$btn-border-radius:              $border-radius-base;

$input-border:                   #ddd;
$input-border-focus:             #dfdfdf;
$input-border-radius:            $border-radius-base;

$panel-bg:                    	 #fff;
$panel-border:                	 $border-color;
$panel-heading-border:           lighten($border-color, 5%);
$panel-list-group-border:        #f2f2f2;
$panel-border-radius:         	 $border-radius-base;
$panel-heading-bg:            	 lighten($brand-light, 3%);
$panel-footer-bg:             	 #fff;

$nav-bg:                         lighten($brand-light, 3%);
$badge-bg:                       darken($brand-light, 10%);
$hover-bg:                       #dfe6f5;

$list-group-item-border:         lighten($border-color, 3%);
$list-group-item-hover:          lighten($brand-light, 3%);
$list-group-item-focus:          darken($brand-light, 3%);
$list-group-select-color:        lighten($brand-light, 2%);
$list-group-active-color:        $brand-info;

$table-border-color:             lighten($border-color, 4%);
$table-striped-color:            lighten($brand-light, 4.5%);
$table-uigrid-color:             lighten($brand-light, 4.5%);

$arrow-width:         			     8px;
$arrow-color:         			     #fff;
$arrow-outer-width:   			     ($arrow-width + 1);
$arrow-outer-color:   			     rgba(0,0,0,.1);

$switch-width:                   35px;
$switch-height:                  20px;
$switch-md-width:                40px;
$switch-md-height:               24px;
$switch-lg-width:                50px;
$switch-lg-height:               30px;

$app-aside-width:                200px;
$app-aside-nav-height:           40px;
$app-aside-folded-width:         60px;
$app-aside-folded-nav-height:    50px;
$app-aside-dock-media:           992px;

$app-header-height:              52px;
$app-header-md-height:			  	 60px;

$app-footer-height:              42px;

$scroll-bar-width:               7px;
$butterbar-height:               3px;
$butterbar-time:                 0.75s;

$off-screen-width:               75%;

$screen-xxs-max:                 479px;

// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs:                  480px !default;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min:              $screen-xs !default;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone:               $screen-xs-min !default;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm:                  768px !default;
$screen-sm-min:              $screen-sm !default;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet:              $screen-sm-min !default;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md:                  992px !default;
$screen-md-min:              $screen-md !default;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop:             $screen-md-min !default;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg:                  1200px !default;
$screen-lg-min:              $screen-lg !default;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop:          $screen-lg-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1) !default;
$screen-sm-max:              ($screen-md-min - 1) !default;
$screen-md-max:              ($screen-lg-min - 1) !default;
