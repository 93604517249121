$invert: 0.89 ;

html.dark-theme {

  filter: invert($invert) hue-rotate(180deg);

  img {
    filter: invert($invert) hue-rotate(180deg);
  }

  .btn-success, .btn-danger, .btn-warning, .btn-primary, .btn-info,
  i.fa {
    filter: invert($invert) hue-rotate(180deg)  brightness(1.3);
  }

  .btn-success  i.fa, .btn-danger  i.fa, .btn-warning  i.fa, .btn-primary  i.fa, .btn-info  i.fa, .btn-default i.fa {
    filter: none;
  }

  .modal-backdrop {
    background-color:#f8f8f8 ;
  }

}
