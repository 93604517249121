@import "~@ng-select/ng-select/themes/default.theme.css";

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input,
.ng-select .ng-select-container .ng-value-container{
  padding-left:16px
}

.ng-select.ng-select-opened>.ng-select-container{
  background:auto;
  border-color:$input-border
}

ng-select.ng-invalid .ng-select-container {
  border-color: #c9302c;
}

.ng-select.ng-select-disabled>.ng-select-container {
  background-color: #eeeeee;
  color: #555555;
}
