

.pager {
    padding-left: 0;
    margin: 20px 0;
    text-align: center;
    list-style: none;
}

.pager li {
    display: inline;
}

.pager li > a,
.pager li > span {
    display: inline-block;
    padding: 5px 14px;
    background-color: transparent;
    border: 0px solid #ddd;
}

.pager li > a:hover,
.pager li > a:focus {
    text-decoration: none;
}

.pager .next > a,
.pager .next > span {
    float: right;
}

.pager .previous > a,
.pager .previous > span {
    float: left;
}

.pager .disabled > a,
.pager .disabled > a:hover,
.pager .disabled > a:focus,
.pager .disabled > span {
    color: $text-color;
    cursor: not-allowed;
    background-color: $brand-white;
    opacity: 0.65;
}

*::-webkit-input-placeholder {
    color: lighten($border-color, 10%);
}

*:-moz-placeholder {
    color:  lighten($border-color, 10%);
}

*::-moz-placeholder {
    color:  lighten($border-color, 10%);
}

*:-ms-input-placeholder {
    color:  lighten($border-color, 10%);
}


.table > thead > tr > th {
    vertical-align: bottom;
    border-bottom: 2px solid #ddd;
}

.panel,
.card { margin-bottom:20px; border-radius:4px; -webkit-box-shadow:0 2px 2px rgba(0,0,0,0.05); box-shadow:0 2px 2px rgba(0,0,0,0.05); }

.panel,
.card { background:$brand-white; border:1px solid #e7e7e7;}
.panel-heading,
.card-heading { border-bottom:1px solid #e7e7e7; background-color:#fcfcfc }
