/*Charts*/
.jqstooltip{
  background-color: rgba(0,0,0,0.8) !important;
  border: solid 1px #000 !important;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  padding: 5px 10px !important;
  @include box-sizing(content-box);
  max-height: 12px;
}

// easypie
.easyPieChart {
  position: relative;text-align: center;
  > div{
    position: relative;
    z-index: 1;
    .text{
      position: absolute;
      width: 100%;
      top: 60%;
      line-height: 1;
    }
    img{
      margin-top: -4px;
    }
  }
  canvas {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0
  }
}

// flot tip
#flotTip,
.flotTip {
  padding: 4px 10px;
  background-color: rgba(0,0,0,0.8);
  border: solid 1px #000 !important;
  z-index: 100;
  font-size: 12px;
  color: $brand-white;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

// flot lengend
.legendColorBox{
  > div{
    border: none !important;
    margin: 5px;
    > div{
      border-radius: 10px;
    }
  }
}

// sortable
.sortable-placeholder{list-style: none;border: 1px dashed #CCC;min-height: 50px;margin-bottom: 5px}
